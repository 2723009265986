import React from "react";
import classNames from "classnames/bind";
import dropdownStyles from "./UserInfoDropdownView.module.scss";
import accountSvg from "../../assets/account.svg";
import accountDarkSvg from "../../assets/account-dark.svg";
import Div from "../common/Div";
import { THEME } from "../../helpers/constants";
import withTheme from "../../hoc/withTheme";
import {withTranslation} from "react-i18next";

const cx = classNames.bind(dropdownStyles);

class UserInfoDropdownView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDropdown: false
    };
  }

  toggleDropdown = () =>
    this.setState({
      showDropdown: !this.state.showDropdown
    });

  showDropdown = () => this.setState({ showDropdown: true });
  hideDropdown = () => this.setState({ showDropdown: false });

  getAddressShorthand() {
    return this.props.account.substring(0, 8) + "..." + this.props.account.substring(36, 42);
  }

  render() {
    const { theme , t} = this.props;

    return (
      <div className={dropdownStyles["c-navigation-dropdown"]}>
        <div onMouseEnter={this.showDropdown} onMouseLeave={this.hideDropdown}>
          <div className={dropdownStyles["c-navigation-dropdown__menu-container"]}>
            <div
              className={cx({
                "c-navigation-dropdown__mobile-icon": true,
                "os-icon": true,
                "os-icon-wallet-loaded": true
              })}
            />
            <Div
              className={dropdownStyles["c-user-info__icon"]}
              darkClassName={dropdownStyles["c-user-info__icon--dark"]}
            >
              <div
                className={dropdownStyles["c-user-info__icon-image"]}
                style={{ background: `url(${theme === THEME.DARK ? accountDarkSvg : accountSvg}) no-repeat` }}
              />
            </Div>
            <div className={dropdownStyles["c-navigation-dropdown__text"]}>{this.getAddressShorthand()}</div>
            <div
              onClick={this.toggleDropdown}
              className={cx({
                "c-navigation-dropdown__toggle-button": true,
                "os-icon": true,
                "os-icon-chevron-down": !this.state.showDropdown,
                "os-icon-chevron-up": this.state.showDropdown
              })}
            />
          </div>
          <div
            className={cx({
              "c-navigation-dropdown__list": true,
              "c-navigation-dropdown__list--visible": this.state.showDropdown
            })}
          >
            <ul>
              <li>
                <a
                  href={`https://etherscan.io/address/${this.props.account}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {this.getAddressShorthand()}
                </a>
              </li>
              <li onClick={this.props.logoutAction}>
                <div className={dropdownStyles["c-navigation-dropdown__list-item-text"]}>{t('header.navigation.logout')}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withTheme(UserInfoDropdownView));
