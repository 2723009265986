import { fulfilled, generateStatuses, pending, rejected } from "../../helpers/store";
import { get, keyBy } from "lodash";
import DataAPI from "../../domain/services/DataAPI";
import { createSelector } from "reselect";
import { getStatusSelector } from "../modules/status";
import Storage from "../../domain/services/Storage";
import { getLocationQueryParam } from "../../helpers/window";
import { ZERO } from "../../helpers/constants";
import { convertToBigNumber } from "../../helpers/trade/amountConverters";

const GET_PAIRS = "@pairs/get-pairs";
const GET_PAIRS_PENDING = pending(GET_PAIRS);
const GET_PAIRS_FULFILLED = fulfilled(GET_PAIRS);
const GET_PAIRS_REJECTED = rejected(GET_PAIRS);

const GET_PAIRS_INFO = "@pairs/get-pair-info";
const GET_PAIRS_INFO_PENDING = pending(GET_PAIRS_INFO);
const GET_PAIRS_INFO_FULFILLED = fulfilled(GET_PAIRS_INFO);
const GET_PAIRS_INFO_REJECTED = rejected(GET_PAIRS_INFO);

const GET_PAIRS_LAST_PRICE = "@pairs/get-pairs-last-price";
const GET_PAIRS_LAST_PRICE_PENDING = pending(GET_PAIRS_LAST_PRICE);
const GET_PAIRS_LAST_PRICE_FULFILLED = fulfilled(GET_PAIRS_LAST_PRICE);
const GET_PAIRS_LAST_PRICE_REJECTED = rejected(GET_PAIRS_LAST_PRICE);

const GET_PAIRS_LAST_DATA = "@pairs/get-pairs-last-data";
const GET_PAIRS_LAST_DATA_PENDING = pending(GET_PAIRS_LAST_DATA);
const GET_PAIRS_LAST_DATA_FULFILLED = fulfilled(GET_PAIRS_LAST_DATA);
const GET_PAIRS_LAST_DATA_REJECTED = rejected(GET_PAIRS_LAST_DATA);

const SET_SELECTED_PAIR = "@pairs/set-selected-pair";
const SET_PAIR_LATEST_DATA = "@pairs/set-latest-data";

const defaultState = () => ({
  list: [],
  selectedPair:
    getLocationQueryParam("pair", null) || getLocationQueryParam("token", null) || Storage.getPairToken(null),
  info: {},
  d7Data: {}
});

export default (state = defaultState(), action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PAIRS_FULFILLED:
      return { ...state, list: payload };
    case GET_PAIRS_INFO_FULFILLED:
      return { ...state, info: { ...state.info, ...payload } };
    case GET_PAIRS_LAST_PRICE_FULFILLED:
      const info = Object.entries(payload).reduce((data, [id, lastdata]) => {
        if (data[id]) {
          data[id] = Object.assign({}, data[id], { lastdata });
        } else {
          data[id] = { lastdata };
        }
        return data;
      }, Object.assign({}, state.info));

      return { ...state, info };
    case GET_PAIRS_LAST_DATA_FULFILLED: {
      return { ...state, d7Data: payload };
    }
    case SET_SELECTED_PAIR:
      return { ...state, selectedPair: payload };
    case SET_PAIR_LATEST_DATA: {
      const { id, data } = payload;
      const lastdata = Object.assign({}, get(state, ["info", id, "lastdata"], {}), data);

      return {
        ...state,
        info: {
          ...state.info,
          [id]: {
            ...state.info[id],
            lastdata
          }
        }
      };
    }
    default:
      return state;
  }
};

export const setPairLatestDataAction = (id, data) => {
  return { type: SET_PAIR_LATEST_DATA, payload: { id, data } };
};

export const setSelectedPairEffect = id => async dispatch => {
  Storage.setSelectedPair(id);
  dispatch({ type: SET_SELECTED_PAIR, payload: id });
};

export const getPairsEffect = netId => async dispatch => {
  dispatch({ type: GET_PAIRS_PENDING });
  try {
    const { data } = await DataAPI.getPairs(netId);
    dispatch({ type: GET_PAIRS_FULFILLED, payload: data });
  } catch (e) {
    dispatch({ type: GET_PAIRS_REJECTED, payload: e.message });
  }
};

export const getPairsInfoEffect = pairs => async dispatch => {
  dispatch({ type: GET_PAIRS_INFO_PENDING });
  try {
    let data = await Promise.all(
      pairs.map(pair =>
        DataAPI.getPairInfo(pair)
          .then(({ data }) => data)
          .catch(() => {})
      )
    );
    data = data.filter(item => !!get(item, "id"));
    dispatch({ type: GET_PAIRS_INFO_FULFILLED, payload: keyBy(data, "id") });
  } catch (e) {
    dispatch({ type: GET_PAIRS_INFO_REJECTED, payload: e.message });
  }
};

export const getPairsLastDataEffect = pairs => async dispatch => {
  dispatch({ type: GET_PAIRS_LAST_PRICE_PENDING });
  try {
    const data = await Promise.all(
      pairs.map(pair => DataAPI.getTokensLastPrice(pair).then(res => Object.assign({ id: pair }, res.data)))
    );
    dispatch({ type: GET_PAIRS_LAST_PRICE_FULFILLED, payload: keyBy(data, "id") });
  } catch (e) {
    dispatch({ type: GET_PAIRS_LAST_PRICE_REJECTED, payload: e.message });
  }
};

export const getPairsLastData = netId => async dispatch => {
  dispatch({ type: GET_PAIRS_LAST_DATA_PENDING });
  try {
    const { data } = await DataAPI.get7DayData(netId);
    dispatch({ type: GET_PAIRS_LAST_DATA_FULFILLED, payload: data });
  } catch (e) {
    dispatch({ type: GET_PAIRS_LAST_DATA_REJECTED, payload: e.message });
  }
};

export const pairsListSelector = state => state.pairs.list;

export const pairsInfoSelector = state => state.pairs.info;

export const selectedPairIdSelector = state => state.pairs.selectedPair;

export const pairsD7DataSelector = state => state.pairs.d7Data;

export const pairListStatusSelector = state => getStatusSelector(state, GET_PAIRS);

export const pairsInfoStatusSelector = state => getStatusSelector(state, GET_PAIRS_INFO);

export const pairListWithExtraSelector = createSelector(pairsListSelector, pairListStatusSelector, (pairs, status) => {
  return {
    pairs,
    ...generateStatuses(status)
  };
});

export const pairsWithInfoSelector = createSelector(pairsListSelector, pairsInfoSelector, (pairs, info) => {
  return pairs.map(pair => ({ ...pair, info: info[pair.id] }));
});

export const pairsDictionaryWithInfoSelector = createSelector(pairsWithInfoSelector, pairs => {
  return keyBy(pairs, "id");
});

export const selectedPairSelector = createSelector(
  selectedPairIdSelector,
  pairsD7DataSelector,
  pairsWithInfoSelector,
  (pairId, d7Data, pairs) => {
    let largestVolumePair = null;

    const pair = pairs.find(pair => {
      const { id, tradingPairName = "" } = pair;

      const largestVolumePairVolume = get(d7Data, [get(largestVolumePair, "id"), "volume7d"], 0);
      const volume = get(d7Data, [id, "volume7d"], 0);
      if (convertToBigNumber(largestVolumePairVolume).isLessThan(volume) || largestVolumePair == null) {
        largestVolumePair = pair;
      }

      const isSameId = id === parseInt(pairId, 10);
      const isSameName =
        tradingPairName.toLowerCase() ===
        (pairId || "")
          .toString()
          .replace("-", "/")
          .toLowerCase();
      return isSameId || isSameName;
    });

    return pair || largestVolumePair;
  }
);

export const selectedPairWithInfoSelector = createSelector(selectedPairSelector, pairsInfoSelector, (pair, infos) => {
  if (!pair) {
    return null;
  }
  return { ...pair, info: infos[pair.id] };
});

export const avgPairPriceSelector = createSelector(selectedPairWithInfoSelector, pair => {
  const otherExchanges = get(pair, "info.otherexchanges", []);
  const total = otherExchanges.reduce((sum, exchange) => sum.plus(exchange.price), ZERO);
  return total.div(otherExchanges.length);
});

export const selectedPairD7DataSelector = createSelector(
  selectedPairWithInfoSelector,
  pairsD7DataSelector,
  (pair, d7Data) => {
    const lastdata = get(pair, "info.lastdata", {});
    const d7d = get(d7Data, pair.id, {});

    return {
      price: lastdata.price,
      price7dl: d7d.price7dl || lastdata.price24l,
      price7dh: d7d.price7dh || lastdata.price24h,
      volume7d: d7d.volume7d || lastdata.volume
    };
  }
);
