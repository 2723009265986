import React, { memo } from "react";
import { connect } from "react-redux";
import {
  eulaSelector,
  getEulaEffect,
  setEulaEffect
} from "../../store/modules/eula";
import EulaModal from "./EulaModal";
import { walletAddressSelector } from "../../store/modules/wallet";
import { apiConfigSelector } from "../../store/modules/config";
import useEffectNotNil from "../../hooks/useEffectNotNil";

const Eula = memo(({ eula, setEula, getEula, walletAddress, api }) => {
  useEffectNotNil(
    () => {
      getEula(walletAddress);
    },
    [walletAddress, api]
  );

  function handleEulaClose(data) {
    setEula(walletAddress, data);
  }

  return eula === false && <EulaModal onCloseClick={handleEulaClose} />;
});

const mapState = state => ({
  eula: eulaSelector(state),
  walletAddress: walletAddressSelector(state),
  api: apiConfigSelector(state)
});

const mapDispatch = dispatch => ({
  getEula: caddress => dispatch(getEulaEffect(caddress)),
  setEula: (address, data) => dispatch(setEulaEffect(address, data))
});

export default connect(
  mapState,
  mapDispatch
)(Eula);
