import React from "react";
import { get } from "lodash";
import classNames from "classnames/bind";
import { withRouter } from "react-router";
import { NavLink as Link } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./NavigationView.module.scss";
import EditDropdownView from "./EditDropdownView";
import UserInfoDropdownView from "./UserInfoDropdownView";
import { uiSelector } from "../../store/modules/ui";
import ThemedComponent from "../common/ThemedComponent";
import { clearWalletEffect, walletAddressSelector, walletSelector } from "../../store/modules/wallet";
import SupportDropdown from "./SupportDropdown";
import { THEME } from "../../helpers/constants";
import { withTranslation } from "react-i18next";
import routes from "../../helpers/routes";

const cx = classNames.bind(styles);

class NavigationView extends React.Component {
  renderUserInfo(locale) {
    if (!this.props.walletAddress) {
      return (
        <li className={cx("c-navigation__unlock")}>
          <ThemedComponent
            className={cx("separator")}
            darkClassName={cx("separator--dark")}
            activeClassName={styles.active}
            to={routes.wallet(locale)}
            onClick={this.props.toggleNavigation}
            component={Link}
          >
            <div className={cx("c-navigation__mobile-icon", "os-icon", "os-icon-wallet-loaded")} />
            <span>{this.props.t("header.navigation.unlockWallet")}</span>
          </ThemedComponent>
        </li>
      );
    }

    return (
      <li>
        <UserInfoDropdownView account={this.props.walletAddress} logoutAction={this.props.clearWalletAddress} />
      </li>
    );
  }

  render() {
    const { ui, walletAddress, t, match } = this.props;
    const locale = get(match, "params.locale");

    return (
      <ul
        className={cx({
          "c-navigation": true,
          "c-navigation--visible": this.props.navigationVisible,
          "c-navigation--dark": ui.theme === THEME.DARK
        })}
      >
        <li>
          <Link activeClassName={styles.active} to={routes.exchange(locale)} onClick={this.props.toggleNavigation}>
            <div className={cx("c-navigation__mobile-icon", "os-icon", "os-icon-layout")} />
            <span>{t("header.navigation.exchange")}</span>
          </Link>
        </li>
        {walletAddress && (
          <li>
            <Link activeClassName={styles.active} to={routes.balances(locale)} onClick={this.props.toggleNavigation}>
              <div className={cx("c-navigation__mobile-icon", "os-icon", "os-icon-coins-4")} />
              <span>{t("header.navigation.balances")}</span>
            </Link>
          </li>
        )}
        {walletAddress && (
          <li>
            <Link activeClassName={styles.active} to={routes.orders(locale)} onClick={this.props.toggleNavigation}>
              <div className={cx("c-navigation__mobile-icon", "os-icon", "os-icon-file-text")} />
              <span>{t("header.navigation.orders")}</span>
            </Link>
          </li>
        )}
        <li>
          <EditDropdownView />
        </li>
        <li>
          <SupportDropdown />
        </li>
        {this.renderUserInfo(locale)}
      </ul>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  clearWalletAddress: () => dispatch(clearWalletEffect())
});
const mapStateToProps = state => ({
  ui: uiSelector(state),
  wallet: walletSelector(state),
  walletAddress: walletAddressSelector(state)
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(NavigationView)));
