import { get } from "lodash";
import Api from "./Api";
import BigNumber from "bignumber.js";

const getTokens = (netId, marketId) => Api.get("tradingpairs.json", { params: { netId, marketId } });

const get7DayData = netId => Api.get("get_data_7d.json", { params: { netId } });

const getTokensLastPrice = tradingpairId => Api.get("getlastprice.json", { params: { tradingpairId } });

const getTokenInfo = (id, netId) => Api.get("tokeninfo.json", { params: { id, netId } });

const getOrders = (params, config = {}) => Api.get("getorders.json", { params, ...config });

const getOrder = (tradingpairId, salt) => Api.get("getorder.json", { params: { tradingpairId, salt } });

const deleteOrder = (tradingpairId, salt, transaction) =>
  Api.post("deleteorder.json", { tradingpairId, salt: new BigNumber(salt).toFixed(), transaction });

const checkOrder = (tradingpairId, salt) => {
  return Api.get("checkorder.json", { params: { tradingpairId, salt: new BigNumber(salt).toFixed() } });
};

const createTransaction = data => Api.post("wrappingtransactions.json", data);

const getTransactions = (userAddress, netId) =>
  Api.get("wrappingtransactions.json", { params: { userAddress, netId } });

const createOrder = data => Api.post("createorder.json", data);

const executeOrders = data => Api.post("executeorders.json", data);

const getUser = address => Api.get("user.json", { params: { ethaccount: address } });

const setUser = (address, data = {}) => Api.post(`user.json?ethaccount=${address}`, data);

const getBalances = (userAddress, netId) => Api.get("balance.json", { params: { userAddress, netId } });

const getPriceHistory = params => Api.get("pricehistory.json", { params });

const requestToAddToken = async (ethaccount, caddress) => {
  const data = await Api.get("create_request.json", { params: { ethaccount, caddress } });
  const msg = get(data, "data.message");

  if (msg === "True") {
    return data;
  }
  throw new Error(msg.caddress.join(". "));
};

const getRequestedTokens = ethaccount => Api.get("get_token_requests.json", { params: { ethaccount } });

const getUnlistedTokens = () => Api.get("get_unlisted_tokens.json");

const getPairs = netId => Api.get("tradingpairs.json", { params: { netId } });

const getPairInfo = tradingpairId => Api.get("pairinfo.json", { params: { tradingpairId } });

const getActiveTokens = netId => Api.get("activetokens.json", { params: { netId } });

export default {
  getTokens,
  getTokensLastPrice,
  getOrders,
  getOrder,
  deleteOrder,
  checkOrder,
  createOrder,
  getTokenInfo,
  executeOrders,
  getUser,
  setUser,
  getBalances,
  createTransaction,
  getTransactions,
  getPriceHistory,
  requestToAddToken,
  getRequestedTokens,
  getUnlistedTokens,
  get7DayData,
  getPairs,
  getPairInfo,
  getActiveTokens
};
