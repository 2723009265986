import { useRef } from "react";
import useWindowScrollPosition from "./useWindowScrollPosition";

export const SCROLL_DIRECTION = {
  UP: 1,
  DOWN: 2
};

function useScrollDirection() {
  const prevPosition = useRef(0);
  const { y } = useWindowScrollPosition();

  let direction;
  if (y <= 0) {
    direction = undefined;
  } else if (prevPosition.current < y) {
    direction = SCROLL_DIRECTION.DOWN;
  } else if (prevPosition.current > y) {
    direction = SCROLL_DIRECTION.UP;
  }
  prevPosition.current = y;

  return direction;
}

export default useScrollDirection;
