import { get } from "lodash";
import Web3 from "web3";

const { web3, ethereum } = window;

if (ethereum) {
  ethereum.autoRefreshOnNetworkChange = false;
}

const provider = ethereum || get(web3, "currentProvider");

function getAccounts() {
  const web3 = new Web3(provider);
  return web3.eth.getAccounts();
}

export default {
  provider,
  isInstalled: typeof ethereum !== "undefined" || typeof web3 !== "undefined",
  isMetaMask: get(provider, "isMetaMask", false),
  isTrust: get(provider, "isTrust", false),
  isCoinbaseWallet: get(provider, "isCoinbaseWallet", false),
  isCipher: get(provider, "isCipher", false),
  networkVersion: () => {
    const web3 = new Web3(provider);
    return web3.eth.net.getId();
  },
  getAccount: async () => {
    if (provider.enable) {
      const [address] = await provider.enable();
      return address;
    } else {
      const [account] = await getAccounts();
      return account;
    }
  },
  async isLocked() {
    try {
      const [account] = await getAccounts();
      return !account;
    } catch (e) {
      return true;
    }
  }
};
