import get from 'lodash/get';
import {fulfilled, pending, rejected} from '../../helpers/store';
import DataAPI from '../../domain/services/DataAPI';

const GET_EULA = 'GET_EULA';
const GET_EULA_PENDING = pending(GET_EULA);
const GET_EULA_FULFILLED = fulfilled(GET_EULA);
const GET_EULA_REJECTED = rejected(GET_EULA);

const SET_EULA = 'SET_EULA';
const SET_EULA_PENDING = pending(SET_EULA);
export const SET_EULA_FULFILLED = fulfilled(SET_EULA);
const SET_EULA_REJECTED = rejected(SET_EULA);

export default (state = null, action) => {
  const {type, payload} = action;
  switch (type) {
    case GET_EULA_FULFILLED:
    case SET_EULA_FULFILLED:
      return payload;
    default:
      return state;
  }
};

export const getEulaEffect = walletAddress => async dispatch => {
  if (!walletAddress) {
    return;
  }

  dispatch({type: GET_EULA_PENDING});
  try {
    const {data} = await DataAPI.getUser(walletAddress);
    dispatch({type: GET_EULA_FULFILLED, payload: data.message});
  } catch (e) {
    dispatch({type: GET_EULA_REJECTED, payload: get(e, 'data.error', 'Unknown Error')});
  }
};

export const setEulaEffect = (walletAddress, data = {}) => async dispatch => {
  if (!walletAddress) {
    return;
  }

  dispatch({type: SET_EULA_PENDING});
  try {
    await DataAPI.setUser(walletAddress, {message: JSON.stringify(data)});
    dispatch({type: SET_EULA_FULFILLED, payload: 'True'});
  } catch (e) {
    console.log(e);
    dispatch({
      type: SET_EULA_REJECTED, payload: get(e, 'data.error') || get(e, 'message'),
    });
  }
};

export function eulaSelector(state) {
  const {eula} = state;
  switch (eula) {
    case 'True':
      return true;
    case 'False':
      return false;
    default:
      return;
  }
}
