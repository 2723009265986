import { get, omit, pick } from "lodash";
import { createSelector } from "reselect";
import Storage from "../../domain/services/Storage";
import { fulfilled, pending, rejected } from "../../helpers/store";
import { configSelector, defaultNetworkSelector } from "./config";
import BigNumber from "bignumber.js";
import BlockChainService from "../../domain/services/BlockChainService";
import {
  ETH_DECIMALS,
  ETH_GAS_LIMIT,
  NULL_ADDRESS,
  ORDER_STATUS,
  TRANSACTION_TYPE,
  WALLET_TYPES,
  ZERO
} from "../../helpers/constants";
import AlertMessage from "../../components/common/AlertMessage/AlertMessage";
import { calculateTransactionFee } from "../../helpers/trade/marketUtils";
import { toBaseUnitAmount, toUnitAmount } from "../../helpers/trade/amountConverters";
import { ERRORS } from "../../helpers/messages";
import DataAPI from "../../domain/services/DataAPI";
import { setBalanceAction, tokensListSelector, wethTokenSelector } from "../modules-v2/tokens";

export const SET_WALLET = "SET_WALLET";
export const CLEAR_WALLET_ADDRESS = "CLEAR_WALLET_ADDRESS";
const SET_SELECTED_TOKEN = "SET_SELECTED_TOKEN";
const UPDATE_FAVORITE_TOKENS = "UPDATE_FAVORITE_TOKENS";

export const GET_WALLET_BALANCE = "GET_WALLET_BALANCE";
export const GET_WALLET_BALANCE_PENDING = pending(GET_WALLET_BALANCE);
export const GET_WALLET_BALANCE_FULFILLED = fulfilled(GET_WALLET_BALANCE);
export const GET_WALLET_BALANCE_REJECTED = rejected(GET_WALLET_BALANCE);

export const DEPOSIT_ETH = "DEPOSIT_ETH";
export const WITHDRAW_ETH = "WITHDRAW_ETH";

export const RESET_WALLET = "RESET_WALLET";

export const TRANSFER_ETH = "TRANSFER_ETH";
export const TRANSFER_ETH_FULFILLED = fulfilled(TRANSFER_ETH);

const defaultState = () => {
  const wallet = Storage.getWallet({});
  const favoriteTokens = Storage.getFavTokens([]);
  return {
    ...wallet,
    unlocked: wallet.type ? wallet.type !== WALLET_TYPES.WEB3 : false,
    favoriteTokens
  };
};

export default (state = defaultState(), action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_WALLET:
      return {
        ...pick(state, ["selectedToken", "favoriteTokens"]),
        ...payload,
        unlocked: true
      };
    case GET_WALLET_BALANCE_FULFILLED:
      return { ...state, balance: payload };
    case CLEAR_WALLET_ADDRESS:
      return omit(state, ["address", "networkVersion"]);
    case SET_SELECTED_TOKEN:
      return { ...state, selectedToken: payload };
    case UPDATE_FAVORITE_TOKENS:
      return { ...state, favoriteTokens: payload };
    case DEPOSIT_ETH: {
      let balance = new BigNumber(state.balance || 0).minus(payload);
      if (balance.isNegative()) {
        balance = new BigNumber(0);
      }
      return { ...state, balance: balance.toFixed() };
    }
    case WITHDRAW_ETH: {
      let balance = new BigNumber(state.balance || 0).plus(payload);
      return { ...state, balance: balance.toFixed() };
    }
    case TRANSFER_ETH_FULFILLED: {
      return { ...state, balance: payload };
    }
    default:
      return state;
  }
};

export const updateWalletBalanceAction = balance => ({
  type: GET_WALLET_BALANCE_FULFILLED,
  payload: balance
});

export const setWalletEffect = (type, data) => dispatch => {
  const payload = { type, ...data };
  dispatch({ type: SET_WALLET, payload: payload });
  Storage.setWallet(payload);
};

export const getWalletBalanceEffect = () => async (dispatch, getState) => {
  const state = getState();
  const wallet = walletSelector(state);
  const config = configSelector(state);

  dispatch({ type: GET_WALLET_BALANCE_PENDING });
  let bc;
  try {
    bc = new BlockChainService(config, wallet);
    await bc.init();
    bc.start();
    const amount = await bc.getEthBalance();
    dispatch({
      type: GET_WALLET_BALANCE_FULFILLED,
      payload: toUnitAmount(amount, 18).toString()
    });
  } catch (e) {
    dispatch({ type: GET_WALLET_BALANCE_REJECTED, payload: e.message });
  } finally {
    bc.stop();
  }
};

export const clearWalletEffect = () => dispatch => {
  Storage.removeWallet();
  Storage.clearFavTokens();
  dispatch({ type: CLEAR_WALLET_ADDRESS });
};

export const toggleFavoriteTokenEffect = caddress => (dispatch, getState) => {
  const state = getState();
  const favoriteTokens = get(walletSelector(state), "favoriteTokens", []);
  let tokens = [];
  if (favoriteTokens.includes(caddress)) {
    tokens = Storage.removeFavToken(caddress);
  } else {
    tokens = Storage.addFavToken(caddress);
  }
  dispatch({ type: UPDATE_FAVORITE_TOKENS, payload: tokens });
};

export const transferEthEffect = (modal, to, amount, gasPrice) => async (dispatch, getState) => {
  const state = getState();
  const wallet = walletSelector(state);
  const wethToken = wethTokenSelector(state);
  const config = configSelector(state);

  let bc;
  try {
    bc = new BlockChainService(config, wallet);
    await bc.init();
    bc.start();

    const fee = calculateTransactionFee(ETH_GAS_LIMIT, gasPrice);
    let [walletBalance, wethBalance] = await Promise.all([bc.getEthBalance(), bc.getBalance(wethToken)]);
    wethBalance = BigNumber.max(toBaseUnitAmount(wethBalance.minus(wethToken.inOpenOrders || 0), wethToken), ZERO);
    const availableBalance = walletBalance.plus(wethBalance);

    let transferAmount = amount;
    if (availableBalance.isLessThan(transferAmount)) {
      throw new Error(ERRORS.INSUFFICIENT_ETH_BALANCE);
    }

    /**
     * Withdraw
     */
    if (walletBalance.isLessThan(transferAmount)) {
      const withdrawAmount = transferAmount.minus(walletBalance);
      if (wethBalance.isLessThan(withdrawAmount)) {
        throw new Error(ERRORS.INSUFFICIENT_TOKEN_BALANCE({ token: wethToken.fullname }));
      }

      modal.startWithdraw();
      const withdraw = await bc.withdraw(withdrawAmount);
      const withdrawTransaction = await withdraw.sendTransaction();
      modal.next({ time: bc.waitTime() });
      await bc.awaitTransactionSuccess(withdrawTransaction);

      [walletBalance, wethBalance] = await Promise.all([bc.getEthBalance(), bc.getBalance(wethToken)]);
      dispatch(updateWalletBalanceAction(walletBalance.toFixed()));
      dispatch(setBalanceAction(wethToken.address, toUnitAmount(wethBalance, wethToken)));

      const withdrawData = {
        tokenAddress: wethToken.address,
        senderAddress: wallet.address,
        receiverAddress: NULL_ADDRESS,
        type: TRANSACTION_TYPE.WITHDRAW,
        amount: withdrawAmount.toFixed(),
        transactionId: withdrawTransaction
      };
      DataAPI.createTransaction(withdrawData).then(
        () => {},
        () => {}
      );
    }

    /**
     * Transfer
     */
    if (walletBalance.isLessThan(transferAmount.plus(fee))) {
      transferAmount = walletBalance.minus(fee);
    }

    modal.startEthTransfer();
    const transaction = await bc.sendTransaction(to, transferAmount, { gasPrice, gas: ETH_GAS_LIMIT });
    modal.next({ time: bc.waitTime() });
    await bc.awaitTransactionSuccess(transaction);

    const [, wb] = await bc.getEthBalanceNoThrow();
    modal.next();
    if (wb) {
      dispatch(updateWalletBalanceAction(toUnitAmount(wb, ETH_DECIMALS)));
    }
    setTimeout(() => {
      modal.close();
    }, 3000);
  } catch (e) {
    AlertMessage.showMessage(e.message);
    modal.close();
  } finally {
    bc.stop();
  }
};

export const resetWalletEffect = modal => async (dispatch, getState) => {
  const state = getState();
  const config = configSelector(state);
  const wallet = walletSelector(state);
  const walletAddress = walletAddressSelector(state);
  const networkVersion = networkVersionSelector(state);
  const tokens = tokensListSelector(state);

  let bc;
  let err;
  try {
    bc = new BlockChainService(config, wallet);
    await bc.init();
  } catch (e) {
    err = e;
    AlertMessage.showMessage(e.message);
    console.log(e);
  }

  if (!err) {
    bc.start();
    /**
     * Set allowance to 0 of all listed tokens
     */
    try {
      let allowances = await bc.getAllowances(tokens);
      allowances = allowances.filter(({ allowance }) => allowance.isGreaterThan(0));
      if (allowances.length > 0) {
        modal.showReleaseTokens({ release: allowances.length });
        for (const token of allowances) {
          const { address } = token;
          try {
            await bc.setAllowance({ address }, ZERO);
          } catch (e) {}
        }
      }
    } catch (e) {
      AlertMessage.showMessage(e.message);
    }

    /**
     * Withdraw all available weth
     */
    try {
      const wethBalance = await bc.getWethBalance();
      if (wethBalance.isGreaterThan(0)) {
        const withdraw = await bc.withdraw(wethBalance);
        modal.startWithdraw();
        const withdrawTransaction = await withdraw.sendTransaction();
        modal.next({ time: bc.waitTime() });
        await bc.awaitTransactionSuccess(withdrawTransaction);
        DataAPI.createTransaction({
          tokenAddress: bc.getEtherTokenAddress(),
          senderAddress: bc.getMyAddress(),
          receiverAddress: NULL_ADDRESS,
          type: TRANSACTION_TYPE.WITHDRAW,
          amount: wethBalance.toFixed(),
          transactionId: withdrawTransaction
        }).then(
          () => {},
          () => {}
        );
      }
    } catch (e) {
      AlertMessage.showMessage(e.message);
    }

    /**
     * Fetch and delete all user orders
     */
    try {
      const params = { makerAddress: walletAddress, netId: networkVersion, status: ORDER_STATUS.UNFILLED };
      const { data: partialOrders } = await DataAPI.getOrders(params);
      let orders = await Promise.all(
        partialOrders.map(order => {
          return DataAPI.getOrder(order.token, order.salt).then(res => get(res, "data.data"));
        })
      );
      orders = orders.filter(order => !!order);
      if (orders.length > 0) {
        modal.startCancel();
        const cancelTransaction = await bc.cancelOrders(orders);
        modal.next({ time: bc.waitTime() });
        await bc.awaitTransactionSuccess(cancelTransaction);
        orders.forEach(({ tokenAddress, salt }) => {
          DataAPI.deleteOrder(tokenAddress, salt, cancelTransaction).then(
            () => {},
            () => {}
          );
        });
      }
    } catch (e) {
      AlertMessage.showMessage(e.message);
    }

    modal.showWalletReset();
    setTimeout(modal.close, 3000);

    bc.stop();
    dispatch({ type: RESET_WALLET });
  }
};

export function walletSelector(state) {
  return state.wallet;
}

export function walletUnlockedSelector(state) {
  return get(walletSelector(state), "unlocked", false);
}

export const walletAddressSelector = createSelector(walletSelector, wallet => {
  const address = get(wallet, "address");
  if (address) {
    return address.toLowerCase();
  }
  return address;
});

export function walletBalanceSelector(state) {
  return get(walletSelector(state), "balance");
}

export function walletNetworkVersionSelector(state) {
  return get(walletSelector(state), "networkVersion");
}

export function networkVersionSelector(state) {
  return walletNetworkVersionSelector(state) || defaultNetworkSelector(state);
}

export function walletSelectedTokenAddress(state) {
  return get(walletSelector(state), "selectedToken");
}

export function favTokensSelector(state) {
  return get(walletSelector(state), "favoriteTokens", []);
}
