export function uniqueId() {
  return `_${Math.random()
    .toString(36)
    .substr(2, 9)}`;
}

export function replaceStringPlaceholder(templateString, data) {
  return Object.entries(data).reduce((string, [key, value]) => {
    return string.replace(new RegExp(`{{${key}}}`, "g"), value);
  }, templateString);
}
