import { get } from "lodash";
import store from "../../store";
import { updateWalletBalanceAction, walletSelector } from "../../store/modules/wallet";
import { configSelector } from "../../store/modules/config";
import { toBaseUnitAmount, toUnitAmount } from "../../helpers/trade/amountConverters";
import DataAPI from "../services/DataAPI";
import {
  ETH_DECIMALS,
  NULL_ADDRESS,
  TRANSACTION_TYPE,
  UNLIMITED_ALLOWANCE_IN_BASE_UNITS,
  UNLOCK_TYPE,
  ZERO
} from "../../helpers/constants";
import BlockChainService from "../services/BlockChainService";
import { ERRORS } from "../../helpers/messages";
import { setBalanceAction, setInOpenOrders } from "../../store/modules-v2/tokens";

export async function createOrder(
  modal,
  makerAmount,
  takerAmount,
  makerToken,
  takerToken,
  tradingpairId,
  type,
  oldSalt
) {
  const { getState, dispatch } = store;
  const state = getState();
  const wallet = walletSelector(state);
  const config = configSelector(state);

  const price = makerAmount.div(takerAmount);
  const makerAmountInOpenOrders = toBaseUnitAmount(get(makerToken, "inOpenOrders", 0), makerToken);
  let makerAmountNeeded = makerAmount.plus(makerAmountInOpenOrders);

  let bc;
  let err;
  try {
    bc = new BlockChainService(config, wallet);
    await bc.init();
    bc.start();

    const etherTokenAddress = bc.getEtherTokenAddress();
    const { address: makerTokenAddress } = makerToken;

    if (makerTokenAddress === etherTokenAddress) {
      const wethBalance = await setDeposit(modal, bc, makerAmountNeeded, makerAmountInOpenOrders);
      if (wethBalance.isLessThan(makerAmountNeeded)) {
        makerAmount = wethBalance;
        makerAmountNeeded = makerAmountInOpenOrders.plus(makerAmount).integerValue();
        takerAmount = makerAmount.div(price);
      }

      dispatch(setBalanceAction(makerTokenAddress, toUnitAmount(wethBalance, makerToken)));
      dispatch(updateWalletBalanceAction(toUnitAmount(await bc.getEthBalance(), ETH_DECIMALS)));
    }

    dispatch(
      setBalanceAction(
        makerTokenAddress,
        toUnitAmount(await checkBalance(bc, makerToken, makerAmountNeeded), makerToken)
      )
    );

    await setAllowance(modal, bc, makerToken, makerAmountNeeded);
    dispatch(updateWalletBalanceAction(toUnitAmount(await bc.getEthBalance(), ETH_DECIMALS)));

    modal.startCreate();
    const order = await bc.createOrder(makerAmount, takerAmount, makerToken, takerToken);

    const {
      data: { data }
    } = await DataAPI.createOrder({ ...order, type, old_salt: oldSalt, tradingpairId });
    const inOpenOrders = toUnitAmount(makerAmountInOpenOrders.plus(makerAmount), makerToken);
    dispatch(setInOpenOrders(makerTokenAddress, inOpenOrders));
    return data;
  } catch (e) {
    err = e;
  } finally {
    modal.close();
    if (bc) {
      bc.stop();
    }
  }
  if (err) {
    throw err;
  }
}

async function checkBalance(bc, token, amount) {
  const balance = await bc.getBalance(token);
  if (balance.isLessThan(amount)) {
    throw new Error(ERRORS.INSUFFICIENT_TOKEN_BALANCE({ token: token.fullname }));
  }
  return balance;
}

async function setDeposit(modal, bc, amount, minAmount = ZERO) {
  const weth = bc.getEtherTokenAddress();
  const minimumEthBalance = bc.getMinimumEthBalance();
  const token = { address: weth, fullname: "Ethereum" };

  let [walletBalance, wethBalance] = await Promise.all([bc.getEthBalance(), bc.getBalance(token)]);
  walletBalance = walletBalance.minus(minimumEthBalance);

  if (wethBalance.isLessThan(amount)) {
    let depositAmount = amount.minus(wethBalance);
    if (depositAmount.isGreaterThanOrEqualTo(walletBalance)) {
      depositAmount = walletBalance;
    }

    const nextWethBalance = wethBalance.plus(depositAmount);
    if (depositAmount.isLessThanOrEqualTo(0) || nextWethBalance.isLessThan(minAmount)) {
      throw new Error(ERRORS.INSUFFICIENT_ETH_BALANCE);
    }

    modal.startDeposit();
    const transaction = await bc.deposit(depositAmount);
    modal.next({ time: bc.waitTime() });
    await bc.awaitTransactionSuccess(transaction);

    DataAPI.createTransaction({
      tokenAddress: weth.caddress,
      senderAddress: bc.getMyAddress(),
      receiverAddress: NULL_ADDRESS,
      type: TRANSACTION_TYPE.DEPOSIT,
      amount: depositAmount,
      transactionId: transaction
    }).then(
      () => {},
      () => {}
    );

    return nextWethBalance;
  }
  return wethBalance;
}

export async function setAllowance(modal, bc, token, amount) {
  const { nodecimals } = token;
  const allowance = await bc.getAllowance(token);

  if (allowance.isLessThan(amount)) {
    if (nodecimals === 0 && !allowance.isZero()) {
      modal.startResetAllowance({ token });
      const resetTransaction = await bc.setAllowance(token, ZERO);
      modal.next({ time: bc.waitTime() });
      await bc.awaitTransactionSuccess(resetTransaction);
    }

    let pick = UNLOCK_TYPE.UNLIMITED;
    const unitAmount = toUnitAmount(amount, token);
    if (modal.needsPicker()) {
      pick = await modal.startAllowance({ token, disableExact: nodecimals === 0 });
      modal.next({ amount: unitAmount });
    } else {
      modal.startAllowanceWithoutPick({ token, amount: unitAmount });
    }

    let transaction;
    if (pick === UNLOCK_TYPE.EXACT) {
      transaction = await bc.setAllowance(token, amount);
    } else {
      transaction = await bc.setAllowance(token, UNLIMITED_ALLOWANCE_IN_BASE_UNITS);
    }
    modal.next({ time: bc.waitTime() });
    await bc.awaitTransactionSuccess(transaction);
  }
}
