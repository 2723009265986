import { createSelector } from "reselect";
import { selectedPairWithInfoSelector } from "./modules-v2/pairs";
import { tokensSelector } from "./modules-v2/tokens";
import { get, keyBy } from "lodash";

export const selectedPairSelector = createSelector(selectedPairWithInfoSelector, tokensSelector, (pair, tokens) => {
  const tokensObj = keyBy(tokens, "address");

  let token1;
  let token2;
  if (pair && pair.info) {
    const { address1, address2, nodecimals1, nodecimals2, name1, name2 } = pair.info;
    token1 = tokensObj[get(pair, "info.address1")] || {
      name: name1,
      address: address1,
      nodecimals: nodecimals1
    };
    token2 = tokensObj[get(pair, "info.address2")] || {
      name: name2,
      address: address2,
      nodecimals: nodecimals2
    };
  }

  return { ...pair, token1, token2 };
});
