import React from "react";
import { get } from "lodash";
import { Link } from "react-router-dom";
import classNames from "classnames/bind";
import { withRouter } from "react-router";
import ConnectedNavigationView from "./NavigationView";
import styles from "./HeaderView.module.scss";
import ThemedComponent from "../common/ThemedComponent";
import Div from "../common/Div";
import { SCROLL_DIRECTION } from "../../hooks/useScrollDirection";
import routes from "../../helpers/routes";

const cx = classNames.bind(styles);

class HeaderView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // This is only related to mobile navigation
      navigationVisible: false
    };
  }

  UNSAFE_componentWillMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  UNSAFE_componentWillUnmount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  handleClick = e => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }

    this.hideNavigationIfVisible();
  };

  toggleNavigation = () => {
    const { onMenuClick } = this.props;
    onMenuClick();
    //this.setState({ navigationVisible: !this.state.navigationVisible })
  };
  hideNavigationIfVisible = () => this.state.navigationVisible && this.setState({ navigationVisible: false });

  render() {
    const { direction, match } = this.props;

    return (
      <ThemedComponent
        component="header"
        className={cx({
          "header--hide": direction === SCROLL_DIRECTION.DOWN
        })}
        darkClassName={cx("header--dark")}
      >
        <nav
          className={cx("c-header")}
          ref={node => {
            this.node = node;
          }}
        >
          <div className={cx("c-header__container")}>
            <Link to={routes.root(get(match, "params.locale"))} onClick={this.hideNavigationIfVisible}>
              <Div className={cx("c-header__logo")} darkClassName={cx("c-header__logo--dark")} />
            </Link>
            <div className={cx("c-header__mobile-menu-toggle")} onClick={this.toggleNavigation}>
              <div className={cx("os-icon", "os-icon-hamburger-menu-1")} />
            </div>
          </div>
          <ConnectedNavigationView
            navigationVisible={this.state.navigationVisible}
            toggleNavigation={this.toggleNavigation}
          />
        </nav>
      </ThemedComponent>
    );
  }
}

export default withRouter(HeaderView);
