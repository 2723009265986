import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { renderModalData } from "./TermsOfServiceModal";

export default memo(({ onBackClick, onCloseClick }) => {
  const { t } = useTranslation();

  const cp = t("cookiePolicy.text", { returnObjects: true });

  return (
    <div className="d-flex flex-column">
      <h1 className="custom-modal__title">{t("cookiePolicy.title")}</h1>
      {renderModalData(cp)}
      {(onBackClick || onCloseClick) && (
        <div className="d-flex flex-column mt-4">
          <button onClick={onBackClick || onCloseClick} className="custom-modal__close-btn button button--secondary">
            {t("buttons.close")}
          </button>
        </div>
      )}
    </div>
  );
});
