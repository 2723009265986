import BigNumber from "bignumber.js";
import { getOrderWithLeftAmount } from "./ordersCalculations";
import { convertToBigNumber, toBaseUnitAmount } from "./amountConverters";
import { ERRORS } from "../messages";
import { NULL_ADDRESS, ORDER_TYPE, TRANSACTION_TYPE, WALLET_TYPES } from "../constants";
import DataAPI from "../../domain/services/DataAPI";
import { orderCalculationUtils } from "@0x/order-utils";
import TransportU2F from "@ledgerhq/hw-transport-u2f";
import Eth from "@ledgerhq/hw-app-eth";
import { setAllowance } from "../../domain/trade/createOrder";

const { getMakerFillAmount } = orderCalculationUtils;

const MARKET_TYPE = {
  BUY: 1,
  SELL: 2
};

export function findOrdersThatCoverAssetFillAmount(orders, assetFillAmount, operation) {
  let remainingFillAmount = assetFillAmount;
  const resultOrders = [];

  for (const order of orders) {
    const { takerAssetAmountLeft, makerAssetAmountLeft } = getOrderWithLeftAmount(order);

    let amountLeft;
    if (operation === MARKET_TYPE.BUY) {
      amountLeft = makerAssetAmountLeft;
    } else {
      amountLeft = takerAssetAmountLeft;
    }

    if (remainingFillAmount.isGreaterThanOrEqualTo(amountLeft)) {
      resultOrders.push({ ...order, assetFillAmount: amountLeft });
      remainingFillAmount = remainingFillAmount.minus(amountLeft);
    } else if (remainingFillAmount.isGreaterThan(0)) {
      resultOrders.push({ ...order, assetFillAmount: remainingFillAmount });
      remainingFillAmount = new BigNumber(0);
    }

    if (remainingFillAmount.isZero()) {
      break;
    }
  }

  return {
    remainingFillAmount,
    resultOrders
  };
}

export function findOrdersThatCoverTakerAssetFillAmount(orders, takerAssetFillAmount) {
  return findOrdersThatCoverAssetFillAmount(orders, takerAssetFillAmount, MARKET_TYPE.SELL);
}

export function findOrdersThatCoverMakerAssetFillAmount(orders, makerAssetFillAmount) {
  return findOrdersThatCoverAssetFillAmount(orders, makerAssetFillAmount, MARKET_TYPE.BUY);
}

export function calculateTransactionFee(limit, price) {
  return convertToBigNumber(limit).times(price);
}

export async function checkDeposit(bc, modal, weth, amount) {
  let transaction;
  let depositAmount = new BigNumber(0);
  const [wethBalance, walletBalance] = await Promise.all([bc.getBalance(weth), bc.getEthBalance()]);
  const inOpenOrders = toBaseUnitAmount(weth.inOpenOrders || 0, weth);
  const amountNeeded = amount.plus(inOpenOrders);
  if (wethBalance.isLessThan(amountNeeded)) {
    depositAmount = wethBalance.minus(amountNeeded).absoluteValue();
    if (walletBalance.isLessThan(depositAmount)) {
      throw new Error(ERRORS.INSUFFICIENT_ETH_BALANCE);
    }
    modal.startDeposit();
    transaction = await bc.deposit(depositAmount);
    modal.next({ time: bc.waitTime() });
    await bc.awaitTransactionSuccess(transaction);
    const transactionData = {
      tokenAddress: weth.caddress,
      senderAddress: bc.getMyAddress(),
      receiverAddress: NULL_ADDRESS,
      type: TRANSACTION_TYPE.DEPOSIT,
      amount: depositAmount.toFixed(),
      transactionId: transaction
    };
    DataAPI.createTransaction(transactionData).then(
      () => {},
      () => {}
    );
  }

  return wethBalance.plus(depositAmount);
}

export async function checkBalanceAndAllowance(bc, modal, token, amount) {
  const inOpenOrders = toBaseUnitAmount(token.inOpenOrders || 0, token);
  const amountNeeded = amount.plus(inOpenOrders);

  const balance = await bc.getBalance(token);
  if (balance.isLessThan(amountNeeded)) {
    throw new Error(ERRORS.INSUFFICIENT_TOKEN_BALANCE({ token: token.fullname }));
  }

  await setAllowance(modal, bc, token, amountNeeded);
}

export async function checkBalance(bc, token, amount) {
  const balance = await bc.getBalance(token);
  if (balance.isLessThan(amount)) {
    throw new Error(ERRORS.INSUFFICIENT_TOKEN_BALANCE({ token: token.fullname }));
  }
  return balance;
}

export async function checkOrdersLeftAmount(bc, orders) {
  const [states] = await bc.devUtils
    .getOrderRelevantStates(
      orders,
      orders.map(order => order.signature)
    )
    .callAsync();

  return orders.map((order, i) => {
    const { orderTakerAssetFilledAmount } = states[i];
    if (order.type === ORDER_TYPE.BUY) {
      return new BigNumber(order.takerAssetAmount).minus(orderTakerAssetFilledAmount);
    } else {
      const makerAmountFilled = getMakerFillAmount(order, orderTakerAssetFilledAmount);
      return new BigNumber(order.makerAssetAmount).minus(makerAmountFilled);
    }
  });
}

export function isSameAddress(addressOne, addressTwo) {
  if (typeof addressOne != "string" || typeof addressTwo != "string") {
    return false;
  }
  return addressOne.toLowerCase() === addressTwo.toLowerCase();
}

export async function checkEnabledContractData(modal, type) {
  if (type === WALLET_TYPES.LEDGER) {
    let checkTimeout = null;
    let maxTimeout = null;
    const transport = await TransportU2F.create();
    const eth = new Eth(transport);

    async function check(resolve, reject) {
      try {
        const { arbitraryDataEnabled } = await eth.getAppConfiguration();
        if (arbitraryDataEnabled) {
          resolve();
        } else {
          modal.showLedgerEnableContract();
          checkTimeout = setTimeout(() => check(resolve), 5000);
        }
      } catch (e) {
        reject(e);
      }
    }

    await Promise.race([
      new Promise(check),
      new Promise((resolve, reject) => {
        maxTimeout = setTimeout(() => reject(new Error(ERRORS.ETH_APP_ENABLE_CONTRACT_DATA)), 30000);
      })
    ]).finally(() => {
      clearInterval(checkTimeout);
      clearInterval(maxTimeout);
      transport.close();
    });
  }
}
