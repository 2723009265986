export const GTM_TAGS = {
  SPREAD: 'spread',
  WALLET_UNLOCK: {
    MM: "wallet-unlock-mm",
    KEYSTORE: "wallet-unlock-keystore",
    PRIVATE_KEY: "wallet-unlock-private-key",
    LEDGER: "wallet-unlock-ledger",
    TREZOR: "wallet-unlock-trezor"
  },
  THEME: {
    LIGHT: "theme-change-light",
    DARK: "theme-change-dark"
  },
  EDIT: {
    VIEW_ALL: "edit-view-all",
    VIEW_BASIC: "edit-view-basic",
    MY_TRADE: {
      ON: "edit-my-trade-on",
      OFF: "edit-my-trade-off"
    },
    TRADE_HISTORY: {
      ON: "edit-trade-history-on",
      OFF: "edit-trade-history-off"
    },
    MY_OPEN_ORDERS: {
      ON: "edit-my-open-orders-on",
      OFF: "edit-my-open-orders-off"
    },
    BALANCES: {
      ON: " edit-balances-on",
      OFF: "edit-balances-off"
    },
    CHARTS: {
      ON: "edit-charts-on",
      OFF: "edit-charts-off"
    }
  }
};

export function gtag(...data) {
  window.dataLayer.push(...data);
}
