import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import * as modules from "./modules";
import * as modulesV2 from "./modules-v2";
import { CLEAR_WALLET_ADDRESS, RESET_WALLET } from "./modules/wallet";
import { pick } from "lodash";

const appReducers = combineReducers({ ...modules, ...modulesV2 });

const rootReducer = (state, action) => {
  if (action.type === CLEAR_WALLET_ADDRESS) {
    return appReducers(pick(state, ["config"]), action);
  }

  if (action.type === RESET_WALLET) {
    return appReducers(pick(state, ["eula", "wallet", "config", "tokens", "pairs"]), action);
  }

  return appReducers(state, action);
};

let middleware;
if (process.env.NODE_ENV === "development") {
  middleware = composeWithDevTools(applyMiddleware(thunk));
} else {
  middleware = applyMiddleware(thunk);
}

const store = createStore(rootReducer, middleware);

export default store;
