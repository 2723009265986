import { assetDataUtils, generatePseudoRandomSalt, signatureUtils } from "0x.js";
import { getContractAddressesForChainOrThrow } from "@0x/contract-addresses";
import { ERC20TokenContract, WETH9Contract } from "@0x/contract-wrappers";
import { get, toNumber, isFunction } from "lodash";
import { isDevelopment, NULL_ADDRESS, WALLET_TYPES, ZERO } from "../../helpers/constants";
import { BigNumber } from "bignumber.js";
import { addYears, getTime } from "date-fns";
import { ERRORS } from "../../helpers/messages";
import EthgasstationAPI from "./EthgasstationAPI";
import { toBaseUnitAmount, toWeiAmount } from "../../helpers/trade/amountConverters";
import { calculateTransactionFee } from "../../helpers/trade/marketUtils";
import { replaceStringPlaceholder } from "../../helpers/string";
import Provider from "./Provider";
import Web3Wrapper from "./Web3Wrapper";
import DevUtilsContract from "./DevUtilsContract";
import ContractWrappers from "./ContractWrappers";
import { getWalletLabel } from "../../components/modals/BlockChainModal";
import { NULL_BYTES } from "@0x/utils";

const USER_DENIED_CODES = {
  WEB3: 4001,
  LEDGER: 27013
};

const CODES = {
  USER_DENIED: 1
};

const CONTEXT = {
  NONE: 1,
  CREATE: 2,
  MARKET_SELL: 3,
  CANCEL: 4,
  DEPOSIT: 5,
  WITHDRAW: 6,
  TRANSFER: 7
};

const BLOCK_CHAIN_ERRORS = {
  [CONTEXT.TRANSFER]: {
    get [CODES.USER_DENIED]() {
      return ERRORS.USER_DENIED_TRANSFER;
    }
  },
  [CONTEXT.WITHDRAW]: {
    get [CODES.USER_DENIED]() {
      return ERRORS.USER_DENIED_WITHDRAW;
    }
  },
  [CONTEXT.NONE]: {
    get [CODES.USER_DENIED]() {
      return ERRORS.ORDER_REJECTED;
    }
  }
};

class BlockChainService {
  static async getGasStation() {
    const { data } = await EthgasstationAPI.getEthGas();
    return data;
  }

  _gasStation;

  constructor(config, wallet) {
    this.config = config;
    this.wallet = wallet;
  }

  async init() {
    const networkVersion = this.getNetworkVersion();
    if (!this.getEnabledNetworks().includes(networkVersion)) {
      throw new Error(ERRORS.NETWORK_NOT_SUPPORTED);
    }

    this.provider = await Provider.init(
      this.wallet.type,
      this.getNetworkVersion(),
      this.getRpc(),
      this.wallet.privateKey
    );
  }

  get web3Wrapper() {
    return new Web3Wrapper(this.provider);
  }

  get devUtils() {
    return new DevUtilsContract(this.getNetworkVersion(), this.provider);
  }

  get gasStation() {
    return (async () => {
      if (!this._gasStation) {
        this._gasStation = await BlockChainService.getGasStation();
      }
      return this._gasStation;
    })();
  }

  get contractWrappers() {
    return new ContractWrappers(this.provider, this.getNetworkVersion());
  }

  start() {
    if (this.provider) {
      this.provider.start();
    }
  }

  stop() {
    if (this.provider) {
      this.provider.stop();
    }
  }

  async createOrder(makerAssetAmount, takerAssetAmount, makerToken, takerToken, makerFee = ZERO, takerFee = ZERO) {
    const makerAddress = this.getMyAddress();
    const order = {
      chainId: this.getNetworkVersion(),
      makerAssetAmount: makerAssetAmount.toFixed(),
      takerAssetAmount: takerAssetAmount.toFixed(),
      makerAssetData: assetDataUtils.encodeERC20AssetData(makerToken.address),
      takerAssetData: assetDataUtils.encodeERC20AssetData(takerToken.address),
      exchangeAddress: this.getExchangeAddress(),
      makerAddress,
      takerAddress: NULL_ADDRESS,
      senderAddress: NULL_ADDRESS,
      feeRecipientAddress: this.getFeeRecipient(),
      expirationTimeSeconds: new BigNumber(getTime(addYears(new Date(), 1))),
      salt: generatePseudoRandomSalt(),
      makerFee,
      takerFee,
      makerFeeAssetData: NULL_BYTES,
      takerFeeAssetData: NULL_BYTES
    };

    return this.execute(() => signatureUtils.ecSignOrderAsync(this.provider, order, makerAddress));
  }

  async marketSellOrders(signedOrders, takerAssetFillAmount) {
    try {
      const txOpts = await this.getTxOpts();
      txOpts.value = this.calculateProtocolFee(signedOrders, txOpts.gasPrice);

      const marketSellOrders = this.contractWrappers.exchange.marketSellOrdersFillOrKill(
        signedOrders,
        takerAssetFillAmount,
        signedOrders.map(order => order.signature)
      );

      const gas = await this.estimateGasAsync(marketSellOrders, txOpts);
      txOpts.gas = gas;
      await this.checkTransactionFee(gas, txOpts.gasPrice);

      const transaction = await this.execute(() => marketSellOrders.sendTransactionAsync(txOpts));
      return [null, transaction];
    } catch (e) {
      return [e, null];
    }
  }

  async estimateGasAsync(contractTxFunctionObj, txOpts) {
    const gas = await contractTxFunctionObj.estimateGasAsync(txOpts);
    return new BigNumber(gas)
      .times(1.05)
      .integerValue()
      .toNumber();
  }

  calculateProtocolFee(orders, gasPrice) {
    return new BigNumber(150000).times(gasPrice).times(orders.length);
  }

  async cancelOrders(orders) {
    return this.execute(async () =>
      this.contractWrappers.exchange.batchCancelOrders(orders).sendTransactionAsync(await this.getTxOpts())
    );
  }

  async getAllowance(token) {
    return this.devUtils
      .getAssetProxyAllowance(this.getMyAddress(), assetDataUtils.encodeERC20AssetData(token.address))
      .callAsync();
  }

  async setAllowance(token, amount) {
    const contractAddresses = getContractAddressesForChainOrThrow(this.getNetworkVersion());
    const tokenContract = new ERC20TokenContract(token.address, this.provider);

    let txOpts = await this.getTxOpts();
    const approve = tokenContract.approve(contractAddresses.erc20Proxy, amount);
    const gas = await this.estimateGasAsync(approve, txOpts);
    txOpts.gas = gas;
    await this.checkTransactionFee(gas, txOpts.gasPrice);

    return this.execute(
      () => approve.sendTransactionAsync(txOpts),
      CONTEXT.NONE,
      ERRORS.TOKEN_UNLOCK_FAILED({ token: token.fullname })
    );
  }

  getTokensWithAssetData = tokens => {
    return tokens.reduce((data, token) => {
      try {
        data.push({ ...token, assetData: assetDataUtils.encodeERC20AssetData(token.address) });
        return data;
      } catch (e) {
        return data;
      }
    }, []);
  };

  async getBalances(tokens) {
    const tokensWithAssetData = this.getTokensWithAssetData(tokens);

    let data = await this.devUtils
      .getBatchBalances(
        this.getMyAddress(),
        tokensWithAssetData.map(token => token.assetData)
      )
      .callAsync();

    return data.map((balance, i) => ({ ...tokensWithAssetData[i], balance }));
  }

  async getAllowances(tokens) {
    const tokensWithAssetData = this.getTokensWithAssetData(tokens);

    let data = await this.devUtils
      .getBatchAssetProxyAllowances(
        this.getMyAddress(),
        tokensWithAssetData.map(token => token.assetData)
      )
      .callAsync();
    return data.map((allowance, i) => ({ ...tokensWithAssetData[i], allowance }));
  }

  async getBalance(token) {
    const [{ balance }] = await this.getBalances([token]);

    if (!balance) {
      throw new Error(ERRORS.FETCH_TOKEN_BALANCE({ token: token.fullname }));
    }
    return balance;
  }

  async getWethBalance() {
    const address = this.getEtherTokenAddress();
    const token = {
      address,
      fullname: "Ethereum"
    };
    return this.getBalance(token);
  }

  async getBalanceNoThrow(token) {
    try {
      const balance = await this.getBalance(token);
      return [null, balance];
    } catch (e) {
      return [e, null];
    }
  }

  async getEthBalances(addresses) {
    return this.devUtils.getEthBalances(addresses).callAsync();
  }

  async getEthBalance() {
    const [balance] = await this.getEthBalances([this.getMyAddress()]);
    return balance;
  }

  async getEthBalanceNoThrow() {
    try {
      const balance = await this.getEthBalance();
      return [null, balance];
    } catch (e) {
      return [e, null];
    }
  }

  sendTransaction(to, value, txOpts) {
    return this.execute(
      async () =>
        this.web3Wrapper.sendTransactionAsync({
          ...(await this.getTxOpts()),
          to,
          value,
          ...txOpts
        }),
      CONTEXT.TRANSFER,
      null
    );
  }

  transfer(token, to, value, txOpts) {
    return this.execute(
      async () => {
        const tokenContract = new ERC20TokenContract(token.address, this.provider);
        return tokenContract.transfer(to.toLowerCase(), value).sendTransactionAsync({
          ...(await this.getTxOpts()),
          ...txOpts
        });
      },
      CONTEXT.TRANSFER,
      null
    );
  }

  async withdraw(amount) {
    const contract = new WETH9Contract(this.getEtherTokenAddress(), this.provider);
    const txOpts = await this.getTxOpts();
    const withdraw = contract.withdraw(amount);

    const sendTransaction = () =>
      this.execute(async () => {
        return withdraw.sendTransactionAsync(txOpts);
      }, CONTEXT.WITHDRAW);

    const estimateGas = async () => {
      txOpts.gas = await this.estimateGasAsync(withdraw, txOpts);
      return txOpts.gas;
    };

    return {
      sendTransaction,
      estimateGas
    };
  }

  async deposit(amount) {
    const contract = new WETH9Contract(this.getEtherTokenAddress(), this.provider);

    const txData = {
      ...(await this.getTxOpts()),
      value: amount
    };

    const deposit = contract.deposit();
    const gas = await this.estimateGasAsync(deposit, txData);
    txData.gas = gas;
    await this.checkTransactionFee(gas, txData.gasPrice);

    return this.execute(() => deposit.sendTransactionAsync(txData));
  }

  async awaitTransactionSuccess(transactionHash) {
    const { status } = await this.web3Wrapper.awaitTransactionSuccessAsync(transactionHash);
    if (status !== 1) {
      throw new Error(ERRORS.TRANSACTION_FAILED);
    }
  }

  async checkTransactionFee(gas, price, err = ERRORS.NOT_ENOUGH_FOR_FEE) {
    const balance = await this.getEthBalance();
    const fee = calculateTransactionFee(gas, price);
    if (balance.isLessThan(fee)) {
      throw new Error(err);
    }
  }

  async getTxOpts() {
    const options = {
      from: this.getMyAddress()
    };

    const data = await this.gasStation;
    const gasPrice = new BigNumber(data.fast)
      .div(10)
      .plus(1.5)
      .times(0.8)
      .integerValue();
    options.gasPrice = new BigNumber(toWeiAmount(gasPrice, "gwei"));

    return options;
  }

  getExchangeAddress() {
    return this.contractWrappers.exchange.address;
  }

  getMyAddress() {
    return this.wallet.address.toLowerCase();
  }

  getRpcs() {
    const { rpcHost, rpcPort, rpcHostKovan, rpcPortKovan } = this.config;
    return {
      1: `${rpcHost}${rpcPort ? `:${rpcPort}` : ""}`,
      42: `${rpcHostKovan}${rpcPortKovan ? `:${rpcPortKovan}` : ""}`
    };
  }

  getFeeRecipient() {
    return this.config.feeRecipient;
  }

  getRpc() {
    return this.getRpcs()[this.getNetworkVersion()];
  }

  getEnabledNetworks() {
    return get(this.config, "enabledNetworks", []);
  }

  getMinimumEthBalance() {
    return toBaseUnitAmount(get(this.config, "minimumEthBalance", 0), 18);
  }

  getNetworkVersion() {
    return toNumber(get(this.wallet, "networkVersion", this.config.defaultNetwork));
  }

  waitTime() {
    return Math.ceil(get(this._gasStation, "fastWait", 0) * 60);
  }

  getEtherTokenAddress() {
    const contractAddresses = getContractAddressesForChainOrThrow(this.getNetworkVersion());
    return contractAddresses.etherToken;
  }

  getContractAddress(key) {
    return getContractAddressesForChainOrThrow(this.getNetworkVersion())[key];
  }

  getErrorCode(err) {
    let code;
    const { type } = this.wallet;
    switch (type) {
      case WALLET_TYPES.WEB3:
        code = err.code;
        break;
      case WALLET_TYPES.LEDGER:
        code = err.statusCode;
        break;
      default:
    }

    switch (code) {
      case USER_DENIED_CODES.WEB3:
      case USER_DENIED_CODES.LEDGER:
        return CODES.USER_DENIED;
      default:
        return code;
    }
  }

  isUserRejected(err) {
    const code = this.getErrorCode(err);
    return code === CODES.USER_DENIED;
  }

  async execute(fnc, context = CONTEXT.NONE, defaultError = ERRORS.GENERIC_ERROR) {
    let data;
    let err;
    const { type } = this.wallet;

    try {
      data = await fnc();
    } catch (e) {
      if (isDevelopment) {
        console.error(e);
      }
      err = e;
    }

    if (err) {
      const template = get(BLOCK_CHAIN_ERRORS, [context, this.getErrorCode(err)], defaultError);
      const wallet = getWalletLabel(type);

      if (isFunction(template)) {
        err.message = template({ wallet });
      } else {
        err.message = template ? replaceStringPlaceholder(template, { wallet }) : err.message;
      }
      throw err;
    }

    return data;
  }
}

export default BlockChainService;
