import { pending, fulfilled, rejected } from "../../helpers/store";
import { ORDER_STATUS } from "../../helpers/constants";
import DataAPI from "../../domain/services/DataAPI";
import { mapOrder } from "../../helpers/orders";
import { createOrder } from "../../domain/trade/createOrder";
import { convertToBigNumber } from "../../helpers/trade/amountConverters";
import AlertMessage from "../../components/common/AlertMessage/AlertMessage";
import { exchangeUnfilledAddOrderAction } from "./exchangeUnfilledOrders";
import { tokensListObjectSelector } from "../modules-v2/tokens";

const GET_ORDERS = "exchange/dropped-orders/get";
const GET_ORDERS_PENDING = pending(GET_ORDERS);
const GET_ORDERS_FULFILLED = fulfilled(GET_ORDERS);
const GET_ORDERS_REJECTED = rejected(GET_ORDERS);

const REMOVE_ORDER = "exchange/dropped-orders/remove";
const REMOVE_ORDER_PENDING = pending(REMOVE_ORDER);
const REMOVE_ORDER_FULFILLED = fulfilled(REMOVE_ORDER);
const REMOVE_ORDER_REJECTED = rejected(REMOVE_ORDER);

export default function(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ORDERS_FULFILLED:
      return payload;
    case REMOVE_ORDER_FULFILLED:
      return state.filter(order => order.salt !== payload);
    default:
      return state;
  }
}

export const getDroppedOrdersEffect = (netId, walletAddress, tradingpairId) => async dispatch => {
  const params = {
    netId,
    tradingpairId,
    makerAddress: walletAddress,
    status: ORDER_STATUS.DROPPED
  };

  dispatch({ type: GET_ORDERS_PENDING });
  try {
    const { data } = await DataAPI.getOrders(params);
    dispatch({ type: GET_ORDERS_FULFILLED, payload: data.map(item => ({ ...item, status: ORDER_STATUS.DROPPED })) });
  } catch (e) {
    dispatch({ type: GET_ORDERS_REJECTED, payload: e.message });
  }
};

export const renewOrderEffect = (salt, modal) => async (dispatch, getState) => {
  const state = getState();
  const tokens = tokensListObjectSelector(state);
  const order = droppedOrdersSelector(state).find(order => order.salt === salt);

  if (order) {
    const {
      takerTokenAddress,
      makerTokenAddress,
      makerAssetAmountLeft,
      takerAssetAmountLeft,
      type,
      tradingpairId
    } = order;
    const takerToken = tokens[takerTokenAddress];
    const makerToken = tokens[makerTokenAddress];
    if (takerToken && makerToken) {
      dispatch({ type: REMOVE_ORDER_PENDING });
      try {
        const newOrder = await createOrder(
          modal,
          convertToBigNumber(makerAssetAmountLeft),
          convertToBigNumber(takerAssetAmountLeft),
          makerToken,
          takerToken,
          tradingpairId,
          type,
          salt
        );
        dispatch({ type: REMOVE_ORDER_FULFILLED, payload: salt });
        dispatch(exchangeUnfilledAddOrderAction(newOrder));
      } catch (e) {
        AlertMessage.showMessage(e.message);
        dispatch({ type: REMOVE_ORDER_REJECTED, payload: e.message });
        console.log(e);
      }
    }
  }
};

export const droppedOrdersSelector = ({ exchangeDroppedOrders }) => {
  return exchangeDroppedOrders.map(mapOrder);
};
