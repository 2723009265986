import {ASYNC_STATUS, isDevelopment} from "../../helpers/constants";

// Reducer
export default (state = {}, action) => {
  const { type, payload, statusName } = action;
  const matches = new RegExp(`(.*)_(${ASYNC_STATUS.PENDING}|${ASYNC_STATUS.REJECTED})`).exec(type);

  if (!matches) return state;

  const [, requestName, requestState] = matches;

  if (isDevelopment && requestState === ASYNC_STATUS.REJECTED) {
    console.error(type, " => ", payload);
  }

  if (statusName) {
    return {
      ...state,
      [requestName]: {
        ...state[requestName],
        [statusName]: requestState === ASYNC_STATUS.REJECTED ? payload : null
      }
    };
  }

  return {
    ...state,
    [requestName]: requestState === ASYNC_STATUS.REJECTED ? payload : null
  };
};
