import {BigNumber} from 'bignumber.js';
import {get, toNumber} from 'lodash';
import {fromWei, toWei} from 'web3-utils';
import {Web3Wrapper} from '@0x/web3-wrapper';

export function convertToBigNumber(amount) {
  if (amount instanceof BigNumber) {
    return amount;
  }
  return new BigNumber(amount);
}

export function toUnitAmount(amount, token, canBeNegative = false) {
  const decimals = get(token, "nodecimals", token);
  let number = new BigNumber(convertToBigNumber(amount).toFixed(0));

  if (canBeNegative) {
    let isNegative = number.isNegative();
    let unitAmount = Web3Wrapper.toUnitAmount(number.absoluteValue(), decimals);
    if (isNegative) {
      unitAmount = unitAmount.negated();
    }
    return unitAmount;
  }
  return Web3Wrapper.toUnitAmount(number, decimals);
}

export function toBaseUnitAmount(amount, token) {
  const decimals = get(token, "nodecimals", token);
  let number = convertToBigNumber(new BigNumber(amount).toFixed(decimals));
  return Web3Wrapper.toBaseUnitAmount(number, decimals);
}

export function toWeiAmount(amount, unit) {
  let number = convertToBigNumber(amount).toString();
  return new BigNumber(toWei(number, unit).toString());
}

export function fromWeiAmount(amount, unit) {
  let number = convertToBigNumber(amount).toString();
  return new BigNumber(fromWei(number, unit).toString());
}

export function toFixedAmount(amount, decimalPlaces = 4) {
  let number = convertToBigNumber(amount);

  number = number.toFixed();
  const [integer, decimal = ''] = number.split('.');

  let fixedDecimal = '';
  let decimalPlacesWithZeros = decimalPlaces;
  for (const digit of decimal) {
    fixedDecimal += digit;
    if (toNumber(fixedDecimal) === 0) {
      decimalPlacesWithZeros += 1;
    }
    if (decimalPlacesWithZeros === fixedDecimal.length) {
      break;
    }
  }

  const fixedDecimalPlaces = fixedDecimal.length;

  let numWithFixedDecimals = number;
  if (fixedDecimalPlaces > 0) {
    numWithFixedDecimals = `${integer}.${fixedDecimal}`;
  }

  return new BigNumber(numWithFixedDecimals);
}
