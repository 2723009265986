import React, { memo } from "react";
import { Redirect, Route } from "react-router-dom";
import routes from "../../helpers/routes";
import { connect } from "react-redux";
import { walletAddressSelector } from "../../store/modules/wallet";
import { get } from "lodash";

const PublicOnlyRoute = memo(({ component: Component, walletAddress, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        !walletAddress ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: routes.exchange(get(props, "match.params.locale")),
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
});

const mapState = state => ({
  walletAddress: walletAddressSelector(state)
});

export default connect(mapState)(PublicOnlyRoute);
