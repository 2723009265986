import React, { Suspense, PureComponent } from "react";
import * as Sentry from "@sentry/browser";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import Footer from "./components/Footer/Footer";
import { Routes, paths } from "./helpers/routes";
import Theme from "./components/common/Theme";
import PrivateRoute from "./components/common/PrivateRoute";
import PublicOnlyRoute from "./components/common/PublicOnlyRoute";
import AlertMessage from "./components/common/AlertMessage/AlertMessage";
import Eula from "./components/Eula/Eula";
import Header from "./components/Header/Header";
import Web3Listener from "./components/Web3Listener";
import CustomRedirect from "./components/CustomRedirect";
import CheckVersion from "./components/CheckVersion";

export default class App extends PureComponent {
  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      error.__react = true;
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    return (
      <Suspense fallback={null}>
        <Provider store={store}>
          <Router>
            <Theme darkClassName="main-container--dark">
              <div className="main-container">
                <CheckVersion />
                <Eula />
                <Web3Listener />
                <AlertMessage />
                <Route path={paths.root} component={Header} />
                <div className="main-container__route-container">
                  <div className="main-container__route">
                    <Suspense fallback={null}>
                      <Switch>
                        <Route path={paths.exchange} component={Routes.Exchange} />
                        <PrivateRoute path={paths.balances} component={Routes.Balances} />
                        <PrivateRoute path={paths.orders} component={Routes.Orders} />
                        <Route path={paths.help} component={Routes.Help} />
                        <PublicOnlyRoute path={paths.wallet} component={Routes.Wallet} />
                        <Route path={paths.apiDoc} component={Routes.ApiDoc} />
                        <Route path={paths.faq} component={Routes.Faq} />
                        <CustomRedirect />
                      </Switch>
                    </Suspense>
                  </div>
                </div>
                <Footer />
              </div>
            </Theme>
          </Router>
        </Provider>
      </Suspense>
    );
  }
}
