import { ASYNC_STATUS } from "./constants";

export const pending = s => `${s}_${ASYNC_STATUS.PENDING}`;

export const fulfilled = s => `${s}_${ASYNC_STATUS.FULFILLED}`;

export const rejected = s => `${s}_${ASYNC_STATUS.REJECTED}`;

export function generateStatuses(status) {
  return {
    fulfilled: status === ASYNC_STATUS.FULFILLED,
    pending: status === ASYNC_STATUS.PENDING,
    failed: status === ASYNC_STATUS.FULFILLED
  };
}
