import React, { memo } from "react";
import classNames from "classnames";
import PortalModal from "./PortalModal";
import Theme from "./Theme";

const BaseModal = memo(({ onClose, children, className, fullscreen = false }) => {
  function handleModalClick(e) {
    if (e.target.isSameNode(e.currentTarget)) {
      onClose();
    }
  }

  return (
    <PortalModal>
      <Theme darkClassName="base-modal--dark">
        <div
          className={classNames("base-modal", {
            "base-modal--fullscreen": fullscreen
          }, className)}
          onClick={handleModalClick}
        >
          <div className="base-modal__content">{children}</div>
        </div>
      </Theme>
    </PortalModal>
  );
});

export default BaseModal;
