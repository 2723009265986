import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { isDevelopment } from "./helpers/constants";

export const supportedLocales = ["en", "ja", "jp"];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: isDevelopment,
    interpolation: {
      escapeValue: false
    },
    whitelist: supportedLocales,
    detection: {
      order: ["path", "navigator"],
      lookupFromPathIndex: 0,
      checkWhitelist: true
    }
  })
  .then(
    () => {},
    () => {}
  );

export const t = (key, options) => i18n.t(key, options);
