import React, { PureComponent, createRef } from "react";
import classNames from "classnames";
import PortalModal from "./PortalModal";
import Div from "./Div";

export default class CustomModal extends PureComponent {
  static defaultProps = {
    withContainer: true,
    showClose: true
  };

  bg = createRef();
  container = createRef();

  state = {
    scrollPosition: 0
  };

  handleOutsideClick = e => {
    if (e.target.isSameNode(this.bg.current)) {
      this.props.onCloseClick();
    }
  };

  handleScroll = e => {
    this.setState({
      scrollPosition: e.currentTarget.scrollTop
    });
  };

  renderClose = () => (
    <svg
      onClick={this.props.onCloseClick}
      className="custom-modal__close"
      xmlns="http://www.w3.org/2000/svg"
      width="25.456"
      height="25.456"
      viewBox="0 0 25.456 25.456"
    >
      <path
        id="Combined_Shape"
        d="M7.5,18V10.5H0v-3H7.5V0h3V7.5H18v3H10.5V18Z"
        transform="translate(12.728 0) rotate(45)"
        fill="#9b9b9b"
      />
    </svg>
  );

  renderWithContainer = () => (
    <div className="custom-modal__position">
      <div
        className="custom-modal__content-container"
        onScroll={this.handleScroll}
        ref={this.container}
      >
        {this.props.children}
      </div>
      {this.props.showClose && this.renderClose()}
    </div>
  );

  render() {
    const { withContainer, children, className } = this.props;

    return (
      <PortalModal>
        <Div
          className={classNames("custom-modal", className)}
          darkClassName="custom-modal--dark"
        >
          <div
            className="custom-modal__bg"
            ref={this.bg}
            onClick={this.handleOutsideClick}
          />
          {withContainer ? this.renderWithContainer() : children}
        </Div>
      </PortalModal>
    );
  }
}
