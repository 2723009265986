import { difference, get, keyBy, omit, uniq, toString } from "lodash";
import { fulfilled, pending, rejected } from "../../helpers/store";
import { networkVersionSelector, walletAddressSelector, walletSelector } from "./wallet";
import { ORDER_STATUS, ORDER_TYPE } from "../../helpers/constants";
import Api from "../../domain/services/Api";
import DataAPI from "../../domain/services/DataAPI";
import { configSelector } from "./config";
import AlertMessage from "../../components/common/AlertMessage/AlertMessage";
import BlockChainService from "../../domain/services/BlockChainService";
import { checkEnabledContractData } from "../../helpers/trade/marketUtils";
import BigNumber from "bignumber.js";
import { createOrder } from "../../domain/trade/createOrder";
import { tokensListObjectSelector } from "../modules-v2/tokens";
import { getPairsInfoEffect, pairsInfoSelector, pairsWithInfoSelector } from "../modules-v2/pairs";

const GET_OPEN_ORDERS = "GET_OPEN_ORDERS";
const GET_OPEN_ORDERS_PENDING = pending(GET_OPEN_ORDERS);
const GET_OPEN_ORDERS_FULFILLED = fulfilled(GET_OPEN_ORDERS);
const GET_OPEN_ORDERS_REJECTED = rejected(GET_OPEN_ORDERS);

const CANCEL_OPEN_ORDERS = "CANCEL_OPEN_ORDERS";
//const CANCEL_OPEN_ORDERS_PENDING = pending(CANCEL_OPEN_ORDERS);
const CANCEL_OPEN_ORDERS_FULFILLED = fulfilled(CANCEL_OPEN_ORDERS);
//const CANCEL_OPEN_ORDERS_REJECTED = rejected(CANCEL_OPEN_ORDERS);

const GET_TRADE_HISTORY = "GET_TRADE_HISTORY";
const GET_TRADE_HISTORY_PENDING = pending(GET_TRADE_HISTORY);
const GET_TRADE_HISTORY_FULFILLED = fulfilled(GET_TRADE_HISTORY);
const GET_TRADE_HISTORY_REJECTED = rejected(GET_TRADE_HISTORY);

const GET_DROPPED_ORDERS = "GET_DROPPED_ORDERS";
const GET_DROPPED_ORDERS_PENDING = pending(GET_DROPPED_ORDERS);
const GET_DROPPED_ORDERS_FULFILLED = fulfilled(GET_DROPPED_ORDERS);
const GET_DROPPED_ORDERS_REJECTED = rejected(GET_DROPPED_ORDERS);

const PLACE_AGAIN_ORDER = "PLACE_AGAIN_ORDER";
const PLACE_AGAIN_ORDER_PENDING = pending(PLACE_AGAIN_ORDER);
const PLACE_AGAIN_ORDER_FULFILLED = fulfilled(PLACE_AGAIN_ORDER);
const PLACE_AGAIN_ORDER_REJECTED = rejected(PLACE_AGAIN_ORDER);

const defaultState = {
  openOrders: [],
  tradeHistory: [],
  droppedOrders: []
};

export default (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_OPEN_ORDERS_FULFILLED:
      return { ...state, openOrders: payload };
    case GET_TRADE_HISTORY_FULFILLED:
      return { ...state, tradeHistory: payload };
    case GET_DROPPED_ORDERS_FULFILLED:
      return { ...state, droppedOrders: payload };
    case PLACE_AGAIN_ORDER_FULFILLED: {
      return {
        ...state,
        openOrders: state.openOrders.concat([omit(payload, ["old_salt"])]),
        droppedOrders: state.droppedOrders.filter(({ salt }) => salt !== payload.old_salt)
      };
    }
    case CANCEL_OPEN_ORDERS_FULFILLED:
      const orders = keyBy(payload, "salt");
      return { ...state, openOrders: state.openOrders.filter(order => !orders[order.salt]) };
    default:
      return state;
  }
};

export const getOpenOrdersEffect = () => async (dispatch, getState) => {
  const state = getState();
  const walletAddress = walletAddressSelector(state);
  const netId = networkVersionSelector(state);
  if (!walletAddress || !netId) {
    return;
  }

  const params = {
    makerAddress: walletAddress,
    status: ORDER_STATUS.UNFILLED,
    netId
  };
  dispatch({ type: GET_OPEN_ORDERS_PENDING });
  try {
    let { data } = await Api.get("getorders.json", { params });
    data = data.map(({ amount, price, ...item }) => ({
      ...item,
      amount: amount.toString(),
      price: price.toString()
    }));

    dispatch({ type: GET_OPEN_ORDERS_FULFILLED, payload: data });
    return data;
  } catch (e) {
    dispatch({ type: GET_OPEN_ORDERS_REJECTED, payload: e.message });
  }
};

export const getTradeHistoryEffect = () => async (dispatch, getState) => {
  const state = getState();
  const walletAddress = walletAddressSelector(state);
  const netId = networkVersionSelector(state);
  if (!walletAddress || !netId) {
    return;
  }

  const params = {
    makerAddress: walletAddress,
    takerAddress: walletAddress,
    status: ORDER_STATUS.FILLED,
    logicalOperator: 0,
    netId
  };
  dispatch({ type: GET_TRADE_HISTORY_PENDING });
  try {
    const { data } = await Api.get("getorders.json", { params });
    dispatch({ type: GET_TRADE_HISTORY_FULFILLED, payload: data });
    return data;
  } catch (e) {
    dispatch({ type: GET_TRADE_HISTORY_REJECTED, payload: e.message });
  }
};

export const getDroppedOrdersEffect = (netId, makerAddress) => async dispatch => {
  const params = {
    netId,
    makerAddress,
    status: ORDER_STATUS.DROPPED
  };

  dispatch({ type: GET_DROPPED_ORDERS_PENDING });
  try {
    let { data } = await DataAPI.getOrders(params);
    dispatch({ type: GET_DROPPED_ORDERS_FULFILLED, payload: data });
  } catch (e) {
    dispatch({ type: GET_DROPPED_ORDERS_REJECTED, payload: e.message });
  }
};

export const getOrdersEffect = (networkVersion, address) => async (dispatch, getState) => {
  await Promise.all([dispatch(getOpenOrdersEffect()), dispatch(getDroppedOrdersEffect(networkVersion, address))]);

  const state = getState();
  const info = pairsInfoSelector(state);
  const orders = openOrdersSelector(state);

  const pairs = uniq(orders.map(({ tradingpairId }) => tradingpairId).filter(id => id && !info[id]));
  if (pairs.length > 0) {
    dispatch(getPairsInfoEffect(difference(pairs, Object.keys(info))));
  }
};

async function getOrders(orders) {
  let fullOrders = await Promise.all(
    orders.map(order => {
      return DataAPI.getOrder(order.tradingpairId, order.salt).then(
        ({ data }) => get(data, "data") || null,
        () => null
      );
    })
  );
  fullOrders = fullOrders.filter(order => order !== null);

  if (fullOrders.length === 0) {
    throw new Error("No order found");
  }
  return fullOrders;
}

export const cancelOrdersEffect = (salts, modal) => async (dispatch, getState) => {
  const state = getState();
  const wallet = walletSelector(state);
  const config = configSelector(state);
  const orders = openOrdersSelector(state).filter(order => salts.includes(order.salt));

  let bc;
  try {
    let fullOrders = await getOrders(orders);
    bc = new BlockChainService(config, wallet);
    await bc.init();
    bc.start();

    await checkEnabledContractData(modal, wallet.type);
    modal.startCancel();
    const transaction = await bc.cancelOrders(fullOrders);
    modal.next({ time: bc.waitTime() });
    await bc.awaitTransactionSuccess(transaction);
    modal.next();

    setTimeout(() => {
      modal.close();
    }, 3000);
    dispatch({ type: CANCEL_OPEN_ORDERS_FULFILLED, payload: fullOrders });
    fullOrders.forEach(order => {
      return DataAPI.deleteOrder(order.tradingpairId, order.salt, transaction).then(
        () => {},
        () => {}
      );
    });
  } catch (e) {
    console.log(e);
    modal.close();
    AlertMessage.showMessage(e.message);
  } finally {
    if (bc) {
      bc.stop();
    }
  }
};

export const placeAgainOrderEffect = (modal, salt) => async (dispatch, getState) => {
  const state = getState();
  const pairs = pairsWithInfoSelector(state);
  const tokens = tokensListObjectSelector(state);
  const droppedOrder = droppedOrdersSelector(state).find(order => order.salt === salt);
  const pair = pairs.find(pair => {
    return toString(pair.id) === toString(get(droppedOrder, "tradingpairId"));
  });

  const { address1, address2 } = get(pair, "info", {});

  if (droppedOrder && pair && address1 && address2) {
    let { amount, price, type, tradingpairId } = droppedOrder;
    amount = new BigNumber(amount);

    let takerTokenAddress = type === ORDER_TYPE.BUY ? address2 : address1;
    let makerTokenAddress = type === ORDER_TYPE.BUY ? address1 : address2;
    const makerToken = tokens[makerTokenAddress];
    const takerToken = tokens[takerTokenAddress];
    let makerAssetAmount = type === ORDER_TYPE.BUY ? amount.times(price) : amount;
    let takerAssetAmount = type === ORDER_TYPE.BUY ? amount : amount.times(price);

    dispatch({ type: PLACE_AGAIN_ORDER_PENDING });
    try {
      const order = await createOrder(
        modal,
        makerAssetAmount,
        takerAssetAmount,
        makerToken,
        takerToken,
        tradingpairId,
        type,
        salt
      );
      const formattedOrder = {
        date: order.time,
        pair: `${makerToken.name} / ${takerToken.name}`.toUpperCase(),
        maker: order.makerAddress,
        taker: order.takerAddress,
        salt: order.salt,
        token: order.tokenAddress,
        type: order.type,
        status: order.status,
        amount: order.amountleft,
        price: order.price
      };
      dispatch({ type: PLACE_AGAIN_ORDER_FULFILLED, payload: { ...formattedOrder, old_salt: salt } });
    } catch (e) {
      dispatch({ type: PLACE_AGAIN_ORDER_REJECTED, payload: e.message });
      AlertMessage.showMessage(e.message);
      console.log(e);
    }
  } else {
    AlertMessage.showMessage("Invalid order");
  }
};

export function droppedOrdersSelector(state) {
  return state.orders.droppedOrders;
}

export function openOrdersSelector(state) {
  return state.orders.openOrders.concat(droppedOrdersSelector(state));
}

export function tradeHistorySelector(state) {
  return state.orders.tradeHistory;
}
