import React, { PureComponent } from "react";
import classNames from "classnames";
import { ReactComponent as MetaMaskIcon } from "../../assets/icon-metamask.svg";
import { ReactComponent as LedgerIcon } from "../../assets/icon-ledger.svg";
import { ReactComponent as CoinbaseIcon } from "../../assets/icon-coinbase.svg";
import { ReactComponent as CipherIcon } from "../../assets/icon-cipher.svg";
import { ReactComponent as TrustIcon } from "../../assets/icon-trust.svg";
import KeystoreIcon from "../../assets/icon-keystore.png";
import PrivateKeyIcon from "../../assets/icon-private-key.png";
import BaseModal from "../common/BaseModal";
import { WALLET_TYPES } from "../../helpers/constants";
import Web3Service from "../../domain/services/Web3Service";
import Theme from "../common/Theme";
import {t} from '../../i18n'

export function getWalletIcon(type) {
  if (type === WALLET_TYPES.WEB3) {
    if (Web3Service.isMetaMask) {
      return <MetaMaskIcon className="bc-modal__type-icon" />;
    } else if (Web3Service.isCoinbaseWallet) {
      return <CoinbaseIcon className="bc-modal__type-icon" />;
    } else if (Web3Service.isCipher) {
      return <CipherIcon className="bc-modal__type-icon" />;
    } else if (Web3Service.isTrust) {
      return <TrustIcon className="bc-modal__type-icon" />;
    }
  } else if (type === WALLET_TYPES.LEDGER) {
    return <LedgerIcon className="bc-modal__type-icon" />;
  } else if (type === WALLET_TYPES.KEYSTORE_FILE) {
    return <img srcSet={KeystoreIcon} className="bc-modal__type-icon" alt=""/>;
  } else if (type === WALLET_TYPES.PRIVATE_KEY) {
    return <img srcSet={PrivateKeyIcon} className="bc-modal__type-icon" alt=""/>;
  }
  return null;
}

export function getWalletLabel(type) {
  switch (type) {
    case WALLET_TYPES.WEB3:
      if (Web3Service.isMetaMask) {
        return t("walletsName.mm");
      } else if (Web3Service.isCoinbaseWallet) {
        return t("walletsName.coinbase");
      } else if (Web3Service.isCipher) {
        return t("walletsName.cipher");
      } else if (Web3Service.isTrust) {
        return t("walletsName.trust");
      }
      return "";
    case WALLET_TYPES.LEDGER:
      return t("walletsName.ledger");
    default:
      return "";
  }
}

export default class BlockChainModal extends PureComponent {
  render() {
    const { children, type, label, description, renderTypeIcon, className } = this.props;

    return (
      <BaseModal onClose={() => {}}>
        <Theme darkClassName="bc-modal--dark">
          <div className={classNames("bc-modal", className)}>
            {renderTypeIcon ? renderTypeIcon() : getWalletIcon(type)}
            <span className="bc-modal__label">{label}</span>
            <p className="bc-modal__description">{description}</p>
            {children}
          </div>
        </Theme>
      </BaseModal>
    );
  }
}
