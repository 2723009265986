import React from "react";
import { get } from "lodash";
import * as Sentry from "@sentry/browser";
import { ExtraErrorData } from "@sentry/integrations";
import ReactDOM from "react-dom";
import smoothscroll from "smoothscroll-polyfill";
import "./styles/styles-old/main.scss";
import "./i18n";
import App from "./App"
import { isDevelopment, isProduction, SENTRY_ERRORS } from "./helpers/constants";

smoothscroll.polyfill();
if (isProduction) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    integrations: [new ExtraErrorData()],
    ignoreErrors: SENTRY_ERRORS,
    beforeSend(event, hint) {
      const isReactError = get(hint, "originalException.__react", false);
      if (isReactError) {
        return event;
      }
      return null;
    }
  });
}

const rootEl = document.getElementById("root");
ReactDOM.render(<App />, rootEl);
if (isDevelopment && module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    ReactDOM.render(<NextApp />, rootEl);
  });
}
