import React from "react";
import { NavLink as Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import classNames from "classnames/bind";
import dropdownStyles from "./EditDropdownView.module.scss";
import { setUiEffect, setUiViewAllEffect, setUiViewBasicEffect, uiSelector } from "../../store/modules/ui";
import Div from "../common/Div";
import { gtag, GTM_TAGS } from "../../domain/gtm";
import routes from "../../helpers/routes";
import { withTranslation } from "react-i18next";
import { get } from "lodash";

const cDropdown = classNames.bind(dropdownStyles);

class SupportDropdown extends React.Component {
  state = {
    showDropdown: false
  };

  toggleDropdown = () =>
    this.setState({
      showDropdown: !this.state.showDropdown
    });

  showDropdown = () => this.setState({ showDropdown: true });
  hideDropdown = () => this.setState({ showDropdown: false });

  setUiViewBasic = () => {
    this.props.setUiViewBasic();
    gtag({ event: GTM_TAGS.EDIT.VIEW_BASIC });
  };

  setUiViewAll = () => {
    this.props.setUiViewAll();
    gtag({ event: GTM_TAGS.EDIT.VIEW_ALL });
  };

  render() {
    const {t, match} = this.props;
    const locale = get(match, "params.locale");

    return (
      <div className={cDropdown("c-navigation-dropdown")}>
        <div onClick={e => e.preventDefault()} onMouseEnter={this.showDropdown} onMouseLeave={this.hideDropdown}>
          <div className={cDropdown("c-navigation-dropdown__menu-container")}>
            <div className={cDropdown("c-navigation-dropdown__mobile-icon", "os-icon", "os-icon-edit-32")} />
            <Div
              className={cDropdown("c-navigation-dropdown__text")}
              darkClassName={cDropdown("c-navigation-dropdown__text--dark")}
            >
              {t("header.navigation.support")}
            </Div>
            <div
              onClick={this.toggleDropdown}
              className={cDropdown({
                "c-navigation-dropdown__toggle-button": true,
                "os-icon": true,
                "os-icon-chevron-down": !this.state.showDropdown,
                "os-icon-chevron-up": this.state.showDropdown
              })}
            />
          </div>
          <div
            className={cDropdown({
              "c-navigation-dropdown__list": true,
              "c-navigation-dropdown__list--visible": this.state.showDropdown
            })}
          >
            <ul>
              <li>
                <Link to={routes.help(locale)} className={dropdownStyles["c-navigation-dropdown__list-item-text"]}>
                  {t("header.navigation.help")}
                </Link>
              </li>
              <li>
                <Link to={routes.faq(locale)} className={dropdownStyles["c-navigation-dropdown__list-item-text"]}>
                  {t("header.navigation.faq")}
                </Link>
              </li>
              <li>
                <Link to={routes.apiDoc(locale)} className={dropdownStyles["c-navigation-dropdown__list-item-text"]}>
                  {t("header.navigation.api")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  ui: uiSelector(state)
});

const mapDispatch = dispatch => ({
  setUi: data => dispatch(setUiEffect(data)),
  setUiViewAll: () => dispatch(setUiViewAllEffect()),
  setUiViewBasic: () => dispatch(setUiViewBasicEffect())
});

export default compose(connect(mapState, mapDispatch), withRouter, withTranslation())(SupportDropdown);
