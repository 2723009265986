import { createSelector } from "reselect";
import { keyBy } from "lodash";
import { fulfilled, generateStatuses, pending, rejected } from "../../helpers/store";
import { getStatusSelector } from "../modules/status";
import { walletSelector } from "../modules/wallet";
import { configSelector } from "../modules/config";
import BlockChainService from "../../domain/services/BlockChainService";
import DataAPI from "../../domain/services/DataAPI";
import { toUnitAmount } from "../../helpers/trade/amountConverters";
import { checkBalance, isSameAddress } from "../../helpers/trade/marketUtils";
import { TOKEN_GAS_LIMIT } from "../../helpers/constants";
import AlertMessage from "../../components/common/AlertMessage/AlertMessage";

const GET_TOKENS = "@tokens/get-tokens";
const GET_TOKENS_PENDING = pending(GET_TOKENS);
const GET_TOKENS_FULFILLED = fulfilled(GET_TOKENS);
const GET_TOKENS_REJECTED = rejected(GET_TOKENS);

const GET_BALANCES = "@tokens/get-balances";
const GET_BALANCES_PENDING = pending(GET_BALANCES);
const GET_BALANCES_FULFILLED = fulfilled(GET_BALANCES);
const GET_BALANCES_REJECTED = rejected(GET_BALANCES);
const SET_BALANCE = "@tokens/set-balance";

const GET_IN_OPEN_ORDERS = "@tokens/get-in-open-orders";
const GET_IN_OPEN_ORDERS_PENDING = pending(GET_IN_OPEN_ORDERS);
const GET_IN_OPEN_ORDERS_FULFILLED = fulfilled(GET_IN_OPEN_ORDERS);
const GET_IN_OPEN_ORDERS_REJECTED = rejected(GET_IN_OPEN_ORDERS);
const SET_IN_OPEN_ORDERS = "@tokens/set-in-open-orders";

const TOKEN_TRANSFER = "@tokens/token-transfer";
const TOKEN_TRANSFER_PENDING = pending(TOKEN_TRANSFER);
const TOKEN_TRANSFER_FULFILLED = fulfilled(TOKEN_TRANSFER);
const TOKEN_TRANSFER_REJECTED = rejected(TOKEN_TRANSFER);

const defaultState = {
  list: [],
  balances: {},
  inOpenOrders: {}
};

export default (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_TOKENS_FULFILLED:
      return { ...state, list: payload };
    case GET_BALANCES_FULFILLED:
      return { ...state, balances: payload };
    case GET_IN_OPEN_ORDERS_FULFILLED:
      return { ...state, inOpenOrders: payload };
    case SET_BALANCE:
    case TOKEN_TRANSFER_FULFILLED: {
      const { address, balance } = payload;
      return { ...state, balances: { ...state.balances, [address]: balance } };
    }
    case SET_IN_OPEN_ORDERS: {
      const { address, inOpenOrders } = payload;
      return { ...state, inOpenOrders: { ...state.inOpenOrders, [address]: inOpenOrders } };
    }
    default:
      return state;
  }
};

export const setBalanceAction = (address, balance) => ({ type: SET_BALANCE, payload: { address, balance } });

export const setInOpenOrders = (address, inOpenOrders) => ({
  type: SET_IN_OPEN_ORDERS,
  payload: { address, inOpenOrders }
});

export const getTokensEffect = networkId => async dispatch => {
  dispatch({ type: GET_TOKENS_PENDING });
  try {
    const { data } = await DataAPI.getActiveTokens(networkId);
    const tokens = data.map(({ contract_address, ...item }) => Object.assign(item, { address: contract_address }));

    dispatch({ type: GET_TOKENS_FULFILLED, payload: tokens });
  } catch (e) {
    dispatch({ type: GET_TOKENS_REJECTED, payload: e.message });
  }
};

export const getBalancesEffect = () => async (dispatch, getState) => {
  const state = getState();
  const wallet = walletSelector(state);
  const config = configSelector(state);
  const tokens = tokensListSelector(state);

  dispatch({ type: GET_BALANCES_PENDING });
  let bc;
  try {
    bc = new BlockChainService(config, wallet);
    await bc.init();
    bc.start();
    const data = await bc.getBalances(tokens);
    const balances = data.reduce((balances, item) => {
      balances[item.address] = toUnitAmount(item.balance, item).toString();
      return balances;
    }, {});

    dispatch({ type: GET_BALANCES_FULFILLED, payload: balances });
  } catch (e) {
    dispatch({ type: GET_BALANCES_REJECTED, payload: e.message });
  } finally {
    bc.stop();
  }
};

export const getInOpenOrdersEffect = (address, netId) => async dispatch => {
  dispatch({ type: GET_IN_OPEN_ORDERS_PENDING });
  try {
    const { data } = await DataAPI.getBalances(address, netId);
    const inOpenOrders = data.reduce((inOpenOrders, item) => {
      inOpenOrders[item.caddress] = item.inOpenOrders;
      return inOpenOrders;
    }, {});

    dispatch({ type: GET_IN_OPEN_ORDERS_FULFILLED, payload: inOpenOrders });
  } catch (e) {
    dispatch({ type: GET_IN_OPEN_ORDERS_REJECTED, payload: e.message });
  }
};

export const transferTokenEffect = (token, to, amount, gasPrice, onConfirm) => async (dispatch, getState) => {
  const state = getState();
  const config = configSelector(state);
  const wallet = walletSelector(state);

  let bc;
  try {
    dispatch({ type: TOKEN_TRANSFER_PENDING });
    bc = new BlockChainService(config, wallet);
    await bc.init();
    bc.start();

    let balance = await checkBalance(bc, token, amount);
    const transaction = await bc.transfer(token, to, amount, { gasPrice, gas: TOKEN_GAS_LIMIT });
    if (!isSameAddress(to, wallet.address)) {
      balance = balance.minus(amount);
    }
    dispatch({
      type: TOKEN_TRANSFER_FULFILLED,
      payload: { address: token.address, balance: toUnitAmount(balance, token) }
    });
    if (typeof onConfirm === "function") {
      onConfirm();
    }
    await bc.awaitTransactionSuccess(transaction);
  } catch (e) {
    AlertMessage.showMessage(e.message);
    dispatch({ type: TOKEN_TRANSFER_REJECTED, payload: e.message });
  } finally {
    bc.stop();
  }
};

export const tokensListSelector = state => state.tokens.list;

export const tokensListObjectSelector = createSelector(tokensListSelector, tokens => keyBy(tokens, "address"));

export const tokensBalancesSelector = state => state.tokens.balances;

export const tokensInOpenOrdersSelector = state => state.tokens.inOpenOrders;

export const tokensStatusSelector = state => getStatusSelector(state, GET_TOKENS);

export const tokensListWithExtraSelector = createSelector(
  tokensListSelector,
  tokensStatusSelector,
  (tokens, status) => {
    return {
      tokens,
      ...generateStatuses(status)
    };
  }
);

export const tokensSelector = createSelector(
  tokensListSelector,
  tokensBalancesSelector,
  tokensInOpenOrdersSelector,
  (tokens, balances, inOpenOrders) => {
    return tokens.map(token => ({
      ...token,
      balance: balances[token.address],
      inOpenOrders: inOpenOrders[token.address]
    }));
  }
);

export const tokensObjectSelector = createSelector(tokensSelector, tokens => keyBy(tokens, "address"));

export const wethTokenSelector = createSelector(tokensSelector, tokens => {
  return tokens.find(token => token.name.toLowerCase() === "eth");
});
