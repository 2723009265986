import { useCallback, useEffect, useState } from "react";
import { throttle } from "lodash";

function useWindowScrollPosition(wait = 100) {
  const [position, setPosition] = useState({
    x: window.pageXOffset,
    y: window.pageYOffset
  });

  const handle = useCallback(
    throttle(() => {
      setPosition({
        x: window.pageXOffset,
        y: window.pageYOffset
      });
    }, wait),
    [wait]
  );

  useEffect(() => {
    window.addEventListener("scroll", handle);
    return function() {
      window.removeEventListener("scroll", handle);
    };
  }, [handle]);

  return position;
}

export default useWindowScrollPosition;
