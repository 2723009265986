import { PureComponent } from 'react';
import { createPortal } from 'react-dom';

const modalRoot = document.getElementById('modal-root');
export default class PortalModal extends PureComponent {
  el = document.createElement('div');

  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    return createPortal(this.props.children, this.el);
  }
}
