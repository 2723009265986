import React from "react";
import { THEME } from "../../helpers/constants";
import useTheme from "../../hooks/useTheme";

const Theme = ({ lightClassName = "", darkClassName = "", children }) => {
  const [theme] = useTheme();

  function generateClassName(baseClass = "") {
    switch (theme) {
      case THEME.LIGHT:
        return `${baseClass} ${lightClassName}`.trim();
      case THEME.DARK:
        return `${baseClass} ${darkClassName}`.trim();
      default:
        return "";
    }
  }

  return React.Children.map(children, child => {
    return React.cloneElement(child, {
      className: generateClassName(child.props.className)
    });
  });
};

export default Theme;
