import { ASYNC_STATUS } from "../../helpers/constants";

// Reducer
export default (state = {}, action) => {
  const { type, statusName } = action;
  const matches = new RegExp(`(.*)_(${Object.values(ASYNC_STATUS).join("|")})`).exec(type);

  if (!matches) return state;

  const [, requestName, requestState] = matches;

  if (statusName) {
    return {
      ...state,
      [requestName]: {
        ...state[requestName],
        [statusName]: requestState
      }
    };
  }

  return {
    ...state,
    [requestName]: requestState
  };
};

// Selectors
export const getStatusSelector = (state, ...statusName) => {
  let { status } = state;
  statusName.forEach(name => {
    if (status) {
      status = status[name];
    }
  });
  return status;
};

export const shouldSkipSelector = (state, ...statusName) => {
  const status = getStatusSelector(state, ...statusName);
  return status === ASYNC_STATUS.FULFILLED || status === ASYNC_STATUS.PENDING;
};

export const isFulfilledSelector = (state, ...statusName) => {
  const status = getStatusSelector(state, ...statusName);
  return status === ASYNC_STATUS.FULFILLED;
};

export const isFulfilledOrPendingSelector = (state, ...statusName) => {
  const status = getStatusSelector(state, ...statusName);
  return status === ASYNC_STATUS.FULFILLED || status === ASYNC_STATUS.PENDING;
};

export const isPendingSelector = (state, ...statusName) => {
  const status = getStatusSelector(state, ...statusName);
  return status === ASYNC_STATUS.PENDING;
};
