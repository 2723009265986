import { useEffect, memo } from "react";
import axios from "axios";
import Storage from "../domain/services/Storage";

const CheckVersion = () => {
  useEffect(() => {
    checkVersions().then(
      () => {},
      e => {
        console.log(e);
      }
    );
  }, []);

  return null;
};

export default memo(CheckVersion);

async function checkVersions() {
  const {
    data: { version }
  } = await axios.get(`${process.env.PUBLIC_URL}/app.json?date=${Date.now()}`);
  const currentVersion = Storage.getAppVer();
  if (currentVersion && currentVersion !== version) {
    Storage.setAppVer(version);
    if (caches) {
      const names = await caches.keys();
      await Promise.all(names.map(name => caches.delete(name)));
    }
    window.location.reload();
  }
}
