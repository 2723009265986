import React, { memo } from "react";
import CustomModal from "../common/CustomModal";
import { getStaticPath } from "../../helpers/img";
import Theme from "../common/Theme";
import { useTranslation } from "react-i18next";

export default memo(function LicenceModal({ onCloseClick }) {
  const { t } = useTranslation();
  const imagePath = getStaticPath("licence.jpg");
  return (
    <Theme darkClassName="licence-modal--dark">
      <CustomModal onCloseClick={onCloseClick} className="licence-modal">
        <div className="licence-modal__img-container">
          <img src={imagePath} alt="" className="licence-modal__img" />
        </div>
        <button className="button button--w-140 button--secondary" onClick={onCloseClick}>
          {t("buttons.close")}
        </button>
      </CustomModal>
    </Theme>
  );
});
