import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUiEffect, themeSelector } from "../store/modules/ui";

function useTheme() {
  const theme = useSelector(themeSelector);
  const dispatch = useDispatch();

  const setTheme = useCallback(value => dispatch(setUiEffect({ theme: value })), [dispatch]);

  return [theme, setTheme];
}

export default useTheme;
