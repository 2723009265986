import CancelToken from "axios/lib/cancel/CancelToken";
import isCancel from "axios/lib/cancel/isCancel";
import { fulfilled, pending, rejected } from "../../helpers/store";
import DataAPI from "../../domain/services/DataAPI";
import { uniqueId } from "../../helpers/string";
import { get } from "lodash";
import { EXCHANGE_UPDATE_ORDERS, REMOVE_ORDER, UPDATE_ORDER } from "./exchangeUnfilledOrders";
import { createSelector } from "reselect";
import { toFixedAmount } from "../../helpers/trade/amountConverters";
import { isAfter } from "date-fns";

const GET_EXCHANGE_ALL_FILLED_ORDERS = "GET_EXCHANGE_ALL_FILLED_ORDERS";
const GET_EXCHANGE_ALL_FILLED_ORDERS_PENDING = pending(GET_EXCHANGE_ALL_FILLED_ORDERS);
const GET_EXCHANGE_ALL_FILLED_ORDERS_FULFILLED = fulfilled(GET_EXCHANGE_ALL_FILLED_ORDERS);
const GET_EXCHANGE_ALL_FILLED_ORDERS_REJECTED = rejected(GET_EXCHANGE_ALL_FILLED_ORDERS);

export const ADD_ALL_FILLED_ORDER = "@exchange-all-filled-orders/add-all-filled-orders";

export default (state = [], action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_EXCHANGE_ALL_FILLED_ORDERS_FULFILLED:
      return payload;
    case GET_EXCHANGE_ALL_FILLED_ORDERS_PENDING:
      return state;
    case REMOVE_ORDER:
    case UPDATE_ORDER:
      const { filledOrders } = payload.exchange;
      return get(filledOrders, "length", 0) > 0 ? state.concat(filledOrders) : state;
    case EXCHANGE_UPDATE_ORDERS:
      const orders = [];
      Object.values(payload).forEach(order => {
        const { assetFillAmount, price, type, isMyOrder, isMeTaker } = order;
        orders.push({
          id: uniqueId(),
          amount: assetFillAmount.toString(),
          time: new Date().toUTCString(),
          belongs: isMyOrder || isMeTaker ? 1 : 0,
          price,
          type
        });
      });
      return state.concat(orders);
    case ADD_ALL_FILLED_ORDER:
      return state.concat([payload]);
    default:
      return state;
  }
};

export const addAllFilledOrderAction = order => ({ type: ADD_ALL_FILLED_ORDER, payload: order });

let source;
export const getAllFilledOrdersEffect = (tradingpairId, ownerAddress) => async dispatch => {
  dispatch({ type: GET_EXCHANGE_ALL_FILLED_ORDERS_PENDING });
  if (source) {
    source.cancel();
    source = null;
  }
  if (!source) {
    source = CancelToken.source();
  }

  try {
    const params = {
      tradingpairId,
      status: 2,
      ownerAddress
    };
    let { data } = await DataAPI.getOrders(params, {
      cancelToken: source.token
    });
    data = data.map(item => ({ ...item, id: uniqueId() }));

    dispatch({ type: GET_EXCHANGE_ALL_FILLED_ORDERS_FULFILLED, payload: data });
  } catch (e) {
    if (!isCancel(e)) {
      dispatch({
        type: GET_EXCHANGE_ALL_FILLED_ORDERS_REJECTED,
        payload: e.message
      });
    }
  }
};

export const allFilledOrdersSelector = state => state.exchangeAllFilledOrders;

export const ohlcOrdersSelector = createSelector(allFilledOrdersSelector, orders => {
  return orders
    .map(item => ({ date: item.time, amount: toFixedAmount(item.price).toNumber() }))
    .sort((item1, item2) => (isAfter(item1.date, item2.date) ? 1 : -1));
});
