import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import classNames from "classnames/bind";
import dropdownStyles from "./EditDropdownView.module.scss";
import { setUiEffect, setUiViewAllEffect, setUiViewBasicEffect, uiSelector } from "../../store/modules/ui";
import Div from "../common/Div";
import { gtag, GTM_TAGS } from "../../domain/gtm";
import { THEME } from "../../helpers/constants";
import { withTranslation } from "react-i18next";

const cDropdown = classNames.bind(dropdownStyles);

class EditDropdownView extends React.Component {
  state = {
    showDropdown: false
  };

  showUi = () => {
    const { location } = this.props;
    return location.pathname.includes("exchange");
  };

  toggleDropdown = () =>
    this.setState({
      showDropdown: !this.state.showDropdown
    });

  showDropdown = () => this.setState({ showDropdown: true });
  hideDropdown = () => this.setState({ showDropdown: false });

  onUIClick = e => {
    const { target } = e;
    const { key, tagOff, tagOn } = target.dataset;

    const { ui } = this.props;
    const value = !ui[key];
    this.props.setUi({ [key]: value });
    if (tagOff && tagOn) {
      if (value) {
        gtag({ event: tagOn });
      } else {
        gtag({ event: tagOff });
      }
    }
  };

  onToggleThemeClick = () => {
    const { ui } = this.props;
    this.props.setUi({
      theme: ui.theme === THEME.LIGHT ? THEME.DARK : THEME.LIGHT
    });

    if (ui.theme === THEME.LIGHT) {
      gtag({ event: GTM_TAGS.THEME.DARK });
    } else if (ui.theme === THEME.DARK) {
      gtag({ event: GTM_TAGS.THEME.LIGHT });
    }
  };

  setUiViewBasic = () => {
    this.props.setUiViewBasic();
    gtag({ event: GTM_TAGS.EDIT.VIEW_BASIC });
  };

  setUiViewAll = () => {
    this.props.setUiViewAll();
    gtag({ event: GTM_TAGS.EDIT.VIEW_ALL });
  };

  render() {
    const { ui, t } = this.props;

    return (
      <div className={cDropdown("c-navigation-dropdown")}>
        <div onClick={e => e.preventDefault()} onMouseEnter={this.showDropdown} onMouseLeave={this.hideDropdown}>
          <div className={cDropdown("c-navigation-dropdown__menu-container")}>
            <div className={cDropdown("c-navigation-dropdown__mobile-icon", "os-icon", "os-icon-edit-32")} />
            <Div
              className={cDropdown("c-navigation-dropdown__text")}
              darkClassName={cDropdown("c-navigation-dropdown__text--dark")}
            >
              {t("header.navigation.edit")}
            </Div>
            <div
              onClick={this.toggleDropdown}
              className={cDropdown({
                "c-navigation-dropdown__toggle-button": true,
                "os-icon": true,
                "os-icon-chevron-down": !this.state.showDropdown,
                "os-icon-chevron-up": this.state.showDropdown
              })}
            />
          </div>
          <div
            className={cDropdown({
              "c-navigation-dropdown__list": true,
              "c-navigation-dropdown__list--visible": this.state.showDropdown
            })}
          >
            <ul>
              <li onClick={this.onToggleThemeClick}>
                <div className={dropdownStyles["c-navigation-dropdown__list-item-text"]}>
                  {t("header.edit.darkTheme")}
                </div>
                {ui.theme === THEME.DARK && <div className={cDropdown("os-icon", "os-icon-check")} />}
              </li>
              {this.showUi() && (
                <div>
                  <li
                    onClick={this.onUIClick}
                    data-key="charts"
                    data-tag-off={GTM_TAGS.EDIT.CHARTS.OFF}
                    data-tag-on={GTM_TAGS.EDIT.CHARTS.ON}
                  >
                    <div className={dropdownStyles["c-navigation-dropdown__list-item-text--none"]}>
                      {t("header.edit.charts")}
                    </div>
                    {ui.charts && <div className={cDropdown("os-icon", "os-icon-check")} />}
                  </li>
                  <li
                    onClick={this.onUIClick}
                    data-key="balances"
                    data-tag-off={GTM_TAGS.EDIT.BALANCES.OFF}
                    data-tag-on={GTM_TAGS.EDIT.BALANCES.ON}
                  >
                    <div className={dropdownStyles["c-navigation-dropdown__list-item-text--none"]}>
                      {t("header.edit.balances")}
                    </div>
                    {ui.balances && <div className={cDropdown("os-icon", "os-icon-check")} />}
                  </li>
                  <li
                    onClick={this.onUIClick}
                    data-key="orders"
                    data-tag-off={GTM_TAGS.EDIT.MY_OPEN_ORDERS.OFF}
                    data-tag-on={GTM_TAGS.EDIT.MY_OPEN_ORDERS.ON}
                  >
                    <div className={dropdownStyles["c-navigation-dropdown__list-item-text--none"]}>
                      {t("header.edit.myOpenOrders")}
                    </div>
                    {ui.orders && <div className={cDropdown("os-icon", "os-icon-check")} />}
                  </li>
                  <li
                    onClick={this.onUIClick}
                    data-key="trades"
                    data-tag-off={GTM_TAGS.EDIT.TRADE_HISTORY.OFF}
                    data-tag-on={GTM_TAGS.EDIT.TRADE_HISTORY.ON}
                  >
                    <div className={dropdownStyles["c-navigation-dropdown__list-item-text--none"]}>
                      {t("header.edit.tradeHistory")}
                    </div>
                    {ui.trades && <div className={cDropdown("os-icon", "os-icon-check")} />}
                  </li>
                  <li
                    onClick={this.onUIClick}
                    data-key="historyedit"
                    data-tag-off={GTM_TAGS.EDIT.MY_TRADE.OFF}
                    data-tag-on={GTM_TAGS.EDIT.MY_TRADE.ON}
                  >
                    <div className={dropdownStyles["c-navigation-dropdown__list-item-text--none"]}>
                      {t("header.edit.myTrades")}
                    </div>
                    {ui.historyedit && <div className={cDropdown("os-icon", "os-icon-check")} />}
                  </li>
                  <li onClick={this.setUiViewBasic}>
                    <div className={dropdownStyles["c-navigation-dropdown__list-item-text--none"]}>
                      {t("header.edit.viewBasic")}
                    </div>
                  </li>
                  <li onClick={this.setUiViewAll}>
                    <div className={dropdownStyles["c-navigation-dropdown__list-item-text--none"]}>
                      {t("header.edit.viewAll")}
                    </div>
                  </li>
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  ui: uiSelector(state)
});

const mapDispatch = dispatch => ({
  setUi: data => dispatch(setUiEffect(data)),
  setUiViewAll: () => dispatch(setUiViewAllEffect()),
  setUiViewBasic: () => dispatch(setUiViewBasicEffect())
});

export default compose(connect(mapState, mapDispatch), withRouter, withTranslation())(EditDropdownView);
