import { lazy } from "react";
import { retry } from "./retry";
import { supportedLocales } from "../i18n";

export default {
  wallet: locale => (locale ? `/${locale}/wallet` : "/wallet"),
  exchange: locale => (locale ? `/${locale}/exchange` : "/exchange"),
  balances: locale => (locale ? `/${locale}/balances` : "/balances"),
  orders: locale => (locale ? `/${locale}/orders` : "/orders"),
  help: locale => (locale ? `/${locale}/help` : "/help"),
  faq: locale => (locale ? `/${locale}/faq` : "/faq"),
  apiDoc: locale => (locale ? `/${locale}/documentation` : "/documentation"),
  root: locale => (locale ? `/${locale}` : "/")
};

export const paths = {
  root: generatePath(""),
  wallet: generatePath("/wallet"),
  exchange: generatePath("/exchange"),
  balances: generatePath("/balances"),
  orders: generatePath("/orders"),
  help: generatePath("/help"),
  faq: generatePath("/faq"),
  apiDoc: generatePath("/documentation")
};

export const Routes = {
  Exchange: lazy(() => retry(() => import("../components/Exchange/ExchangeRoute"))),
  Balances: lazy(() => retry(() => import("../components/Balances/BalancesRoute"))),
  Orders: lazy(() => retry(() => import("../components/Orders/OrdersRoute"))),
  Help: lazy(() => retry(() => import("../components/Help/HelpRoute"))),
  Wallet: lazy(() => retry(() => import("../components/Wallet/WalletRoute"))),
  Faq: lazy(() => retry(() => import("../components/Faq/FaqRoute"))),
  ApiDoc: lazy(() => retry(() => import("../components/ApiDoc/ApiDocRoute")))
};

function generatePath(path, locales = supportedLocales) {
  return `/:locale(${locales.join("|")})?${path}`;
}
