import React, { memo } from "react";
import styles from "./EulaModal.module.scss";
import { useTranslation } from "react-i18next";

export default memo(({ onUSAClick, onCookiePolicyClick }) => {
  const { t } = useTranslation();

  const eula = t("eula.text", { returnObjects: true });

  return (
    <div>
      <p className="custom-modal__title">{t("eula.title")}</p>
      <p className="custom-modal__text">{eula[0]}</p>
      <p className="custom-modal__text">{eula[1]}</p>
      <p className="custom-modal__text">
        {eula[2]}{" "}
        <span className="custom-modal__link" onClick={onCookiePolicyClick}>
          {eula[3]}
        </span>{" "}
        {eula[4]}
      </p>
      <button className={styles.button} onClick={onUSAClick}>
        {t("buttons.notUSCitizen")}
      </button>
    </div>
  );
});
