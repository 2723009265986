import BigNumber from "bignumber.js";

export const MOBILE_WIDTH = 768;

export const WALLET_TYPES = {
  WEB3: 1,
  LEDGER: 2,
  PRIVATE_KEY: 3,
  KEYSTORE_FILE: 4,
  TREZOR: 5
};

export const ORDER_STATUS = {
  UNFILLED: 1,
  FILLED: 2,
  DROPPED: 5
};

export const ORDER_TYPE = {
  BUY: 1,
  SELL: 2
};

export const CONFIG_URL = `${process.env.PUBLIC_URL}/config/config.json`;

export const ASYNC_STATUS = {
  FULFILLED: "FULFILLED",
  PENDING: "PENDING",
  REJECTED: "REJECTED"
};

export const EMPTY_VALUE = "-";

export const TRADE_FORM_TAB = {
  LIMIT: "LIMIT",
  MARKET: "MARKET"
};

export const TRANSFER_MODE = {
  REGULAR: "1",
  EXPRESS: "2"
};

export const ETH_GAS_LIMIT = 21000;

export const TOKEN_GAS_LIMIT = 65000;

export const SUPPORTED_NETWORK = {
  MAINNET: 1,
  ROPSTEN: 3,
  RINKEBY: 4,
  KOVAN: 42
};

export const TRANSACTION_DETAILS_URL = {
  [SUPPORTED_NETWORK.MAINNET]: "https://etherscan.io/tx/",
  [SUPPORTED_NETWORK.KOVAN]: "https://kovan.etherscan.io/tx/"
};

export const ADDRESS_DETAILS_URL = {
  [SUPPORTED_NETWORK.MAINNET]: "https://etherscan.io/address/",
  [SUPPORTED_NETWORK.KOVAN]: "https://kovan.etherscan.io/address/"
};

export const SORT_DIRECTION = {
  ASC: 1,
  DESC: 2
};

export const TRANSACTION_TYPE = {
  DEPOSIT: 0,
  WITHDRAW: 1
};

export const UNLOCK_TYPE = {
  EXACT: 0,
  UNLIMITED: 1
};

export const UNLIMITED_ALLOWANCE_IN_BASE_UNITS = new BigNumber(2).pow(256).minus(1);

export const NULL_ADDRESS = "0x0000000000000000000000000000000000000000";

export const ALERT_TYPE = {
  INFO: 1,
  ERROR: 2,
  SUCCESS: 3
};

export const ZERO = new BigNumber(0);

export const THEME = {
  LIGHT: 1,
  DARK: 2
};

export const isProduction = process.env.NODE_ENV === "production";
export const isDevelopment = process.env.NODE_ENV === "development";

export const SENTRY_ERRORS = [
  /a\[b\].target.className.indexOf is not a function/,
  /DataCloneError: The object can not be cloned/,
  /Unexpected end of input/,
  /Non-Error promise rejection captured with keys: \[object has no keys\]/,
  /Cannot read property 'valueOf' of undefined/,
  /Loading chunk/,
  /AbortError: The user aborted a request/,
  /The network connection was lost/,
  /Cannot read property 'ownerSVGElement' of null/
];

export const ETH_DECIMALS = 18;

export const DEFAULT_ORDER_PROPERTIES = [
  "feeRecipientAddress",
  "makerFee",
  "expirationTimeSeconds",
  "makerAssetAmount",
  "takerTokenAddress",
  "takerAddress",
  "time",
  "takerAssetData",
  "makerAddress",
  "exchangeAddress",
  "status",
  "makerAssetData",
  "makerFeeAssetData",
  "tradingpairId",
  "type",
  "takerFee",
  "chainId",
  "takerAssetAmount",
  "senderAddress",
  "salt",
  "takerFeeAssetData",
  "signature",
  "makerTokenAddress",
  "maker",
  "tokenAddress",
  "price",
  "amountleft"
];
