import React from "react";
import CustomModal from "../common/CustomModal";
import { withTranslation } from "react-i18next";
import { renderModalData } from "./TermsOfServiceModal";

class PrivacyPolicyModal extends React.Component {
  render() {
    const { onCloseClick, t } = this.props;

    const pp = t("privacyPolicy.text", { returnObjects: true });

    return (
      <CustomModal onCloseClick={onCloseClick}>
        <div className="d-flex flex-column">
          <h1 className="custom-modal__title">{t("privacyPolicy.title")}</h1>
          {renderModalData(pp)}
          <div className="d-flex flex-column mt-4">
            <button onClick={onCloseClick} className="custom-modal__close-btn button button--secondary">
              {t("buttons.close")}
            </button>
          </div>
        </div>
      </CustomModal>
    );
  }
}

export default withTranslation()(PrivacyPolicyModal);
