import { createSelector } from "reselect";
import { isBefore } from "date-fns";
import { get, keyBy } from "lodash";
import CancelToken from "axios/lib/cancel/CancelToken";
import isCancel from "axios/lib/cancel/isCancel";
import { fulfilled, pending, rejected } from "../../helpers/store";
import { uniqBy } from "lodash";
import DataAPI from "../../domain/services/DataAPI";
import { walletAddressSelector, walletUnlockedSelector } from "./wallet";
import { ASYNC_STATUS, ORDER_TYPE } from "../../helpers/constants";
import { getStatusSelector } from "./status";
import { mapOrder } from "../../helpers/orders";
import { createOrder } from "../../domain/trade/createOrder";
import AlertMessage from "../../components/common/AlertMessage/AlertMessage";
import { toBaseUnitAmount } from "../../helpers/trade/amountConverters";
import { selectedPairSelector } from "../selectors";

const GET_EXCHANGE_UNFILLED_ORDERS = "GET_EXCHANGE_UNFILLED_ORDERS";
const GET_EXCHANGE_UNFILLED_ORDERS_PENDING = pending(GET_EXCHANGE_UNFILLED_ORDERS);
const GET_EXCHANGE_UNFILLED_ORDERS_FULFILLED = fulfilled(GET_EXCHANGE_UNFILLED_ORDERS);
const GET_EXCHANGE_UNFILLED_ORDERS_REJECTED = rejected(GET_EXCHANGE_UNFILLED_ORDERS);

export const ADD_ORDER = "ADD_ORDER";
export const REMOVE_ORDER = "REMOVE_ORDER";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const REMOVE_ORDERS = "REMOVE_ORDERS";

export const EXCHANGE_UNFILLED_ADD_ORDER = "EXCHANGE_UNFILLED_ADD_ORDER";
export const EXCHANGE_UNFILLED_REMOVE_ORDER = "EXCHANGE_UNFILLED_REMOVE_ORDER";
export const EXCHANGE_UPDATE_ORDERS = "EXCHANGE_UPDATE_ORDERS";

export const UPDATE_UNFILLED_ORDER = "@exchange-unfilled-orders/update-unfilled-order";
export const REMOVE_UNFILLED_ORDER = "@exchange-unfilled-orders/remove-unfilled-order";

export default (state = [], action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_EXCHANGE_UNFILLED_ORDERS_FULFILLED:
      return uniqBy(state.concat(payload), order => order.salt);
    case GET_EXCHANGE_UNFILLED_ORDERS_PENDING:
      return [];
    case ADD_ORDER:
      return state.concat(payload.exchange);
    case UPDATE_ORDER:
      const { salt, amountLeft } = payload.exchange;
      return state.map(order => {
        if (order.salt === salt) {
          return { ...order, amountleft: amountLeft };
        }
        return order;
      });
    case REMOVE_ORDER:
      const { unfilledOrders } = payload.exchange;
      return state.filter(order => order.salt !== unfilledOrders);
    case REMOVE_ORDERS:
      return state.filter(order => !payload.includes(order.salt));
    case EXCHANGE_UNFILLED_REMOVE_ORDER:
      if (Array.isArray(payload)) {
        const orders = keyBy(payload, "salt");
        return state.filter(order => !!orders[order.salt]);
      }
      return state.filter(order => order.salt !== payload);
    case EXCHANGE_UNFILLED_ADD_ORDER:
      return state.concat([payload]);
    case EXCHANGE_UPDATE_ORDERS:
      return state
        .filter(order => {
          const amountleft = get(payload[order.salt], "amountleft");
          if (!amountleft) {
            return true;
          }
          return !amountleft.isZero();
        })
        .map(order => {
          const amountleft = get(payload[order.salt], "amountleft");
          if (!amountleft) {
            return order;
          }
          return {
            ...order,
            amountleft: amountleft.toString()
          };
        });
    case UPDATE_UNFILLED_ORDER:
      return state.map(order => (order.salt === payload.salt ? Object.assign({}, order, payload) : order));
    case REMOVE_UNFILLED_ORDER:
      return state.filter(order => order.salt !== payload);
    default:
      return state;
  }
};

export const updateOrderAction = order => ({ type: UPDATE_UNFILLED_ORDER, payload: order });

export const removeOrderAction = salt => ({ type: REMOVE_UNFILLED_ORDER, payload: salt });

export const addOrderAction = data => ({ type: ADD_ORDER, payload: data });

export const exchangeUnfilledAddOrderAction = order => ({ type: EXCHANGE_UNFILLED_ADD_ORDER, payload: order });

export const exchangeUnfilledRemoveOrderAction = order => ({ type: EXCHANGE_UNFILLED_REMOVE_ORDER, payload: order });

export const exchangeUpdateOrdersAction = orders => ({ type: EXCHANGE_UPDATE_ORDERS, payload: orders });

let source;
export const getUnfilledOrdersEffect = tradingpairId => async dispatch => {
  dispatch({ type: GET_EXCHANGE_UNFILLED_ORDERS_PENDING });
  if (source) {
    source.cancel();
    source = null;
  }
  if (!source) {
    source = CancelToken.source();
  }

  try {
    const params = {
      tradingpairId,
      status: 1
    };
    const { data } = await DataAPI.getOrders(params, {
      cancelToken: source.token
    });
    dispatch({ type: GET_EXCHANGE_UNFILLED_ORDERS_FULFILLED, payload: data });
  } catch (e) {
    if (!isCancel(e)) {
      dispatch({
        type: GET_EXCHANGE_UNFILLED_ORDERS_REJECTED,
        payload: e.message
      });
    }
  }
};

export const tradeCreateOrderEffect = (modal, makerAmount, takerAmount, makerToken, takerToken, type) => async (
  dispatch,
  getState
) => {
  const pair = selectedPairSelector(getState());

  const baseMakerAmount = toBaseUnitAmount(makerAmount, makerToken);
  const baseTakerAmount = toBaseUnitAmount(takerAmount, takerToken);

  if (baseMakerAmount.isZero() || baseTakerAmount.isZero()) {
    return;
  }

  try {
    const order = await createOrder(modal, baseMakerAmount, baseTakerAmount, makerToken, takerToken, pair.id, type);
    dispatch(exchangeUnfilledAddOrderAction(order));
  } catch (e) {
    console.log(e);
    AlertMessage.showMessage(e.message);
  }
};

export const rawUnfilledOrdersSelector = state => state.exchangeUnfilledOrders;

export const unfilledOrdersSelector = createSelector(rawUnfilledOrdersSelector, orders => orders.map(mapOrder));

export function myUnfilledOrdersSelector(state) {
  const address = walletAddressSelector(state);
  const orders = unfilledOrdersSelector(state);
  const unlocked = walletUnlockedSelector(state);

  if (unlocked) {
    return orders.filter(({ takerAddress, makerAddress }) => makerAddress === address || takerAddress === address);
  }
  return [];
}

export const unfilledBuyOrdersSelector = createSelector(unfilledOrdersSelector, orders =>
  orders
    .filter(order => order.type === ORDER_TYPE.BUY)
    .sort((a, b) => {
      const diff = b.price - a.price;
      if (diff === 0) {
        return isBefore(a.time, b.time) ? 1 : -1;
      }
      return diff;
    })
);

export const unfilledSellOrdersSelector = createSelector(unfilledOrdersSelector, orders =>
  orders
    .filter(order => order.type === ORDER_TYPE.SELL)
    .sort((a, b) => {
      const diff = a.price - b.price;
      if (diff === 0) {
        return isBefore(a.time, b.time) ? 1 : -1;
      }
      return diff;
    })
);

export function unfilledOrdersFulfilledSelector(state) {
  return getStatusSelector(state, GET_EXCHANGE_UNFILLED_ORDERS) === ASYNC_STATUS.FULFILLED;
}
