import React, { createRef, useEffect } from "react";
import Web3 from "web3";
import styles from "./Footer.module.scss";
import PrivacyPolicyModal from "./PrivacyPolicyModal";
import TermsOfServiceModal from "./TermsOfServiceModal";
import Div from "../common/Div";
import Text from "../common/Text";
import CookiePolicyModal from "./CookiePolicyModal";
import { connect } from "react-redux";
import { rpcConfigSelector } from "../../store/modules/config";
import useEffectNotNil from "../../hooks/useEffectNotNil";
import Theme from "../common/Theme";
import useToggleState from "../../hooks/useToggleState";
import LicenceModal from "./LicenceModal";
import { useTranslation } from "react-i18next";

const Footer = React.memo(({ rpc }) => {
  const { t } = useTranslation();
  const interval = createRef();
  const [showPrivacyPolicy, setPrivacyPolicy] = React.useState(false);
  const [showTermsOfService, setTermsOfService] = React.useState(false);
  const [showCookiePolicy, setCookiePolicy] = React.useState(false);
  const [lastBlock, setLastBlock] = React.useState("N/A");
  const [licence, toggleLicence] = useToggleState(false);

  useEffect(() => {
    const tm = setTimeout(() => {
      window.location.reload();
    }, 1000 * 60 * 30);

    return () => {
      clearTimeout(tm);
    };
  }, []);

  useEffectNotNil(() => {
    getLastBlock();
    interval.current = setInterval(() => {
      getLastBlock();
    }, 1000 * 30);

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [rpc]);

  async function getLastBlock() {
    try {
      const lastBlock = await new Web3(rpc).eth.getBlockNumber();
      setLastBlock(lastBlock);
    } catch (e) {}
  }

  function togglePrivacyPolicy() {
    setPrivacyPolicy(!showPrivacyPolicy);
  }

  function toggleTermsOfService() {
    setTermsOfService(!showTermsOfService);
  }

  function toggleCookiePolicy() {
    setCookiePolicy(!showCookiePolicy);
  }

  function renderBlockIcon() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path
          id="Combined_Shape"
          d="M16,32A16,16,0,0,1,4.686,4.686,16,16,0,0,1,27.314,27.314,15.9,15.9,0,0,1,16,32Zm6.546-13.091L16,22.255v5.381Zm-13.818,0h0l6.545,8.727V22.255L8.728,18.909ZM16,3.637h0l0,17.452,6.546-3.551Zm-.727,0-6.546,13.9,6.546,3.552Z"
        />
      </svg>
    );
  }

  function renderContactIcon() {
    return (
      <svg id="Group_64" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <defs>
          <clipPath id="clip-path">
            <path
              id="Shape"
              d="M16.853,19.394a1.6,1.6,0,0,1-.663-.162l-4.8-3.873-3.08,2.954a.563.563,0,0,1-.32.111.431.431,0,0,1-.182-.04s-.23-.028-.511-.965S5.587,11.56,5.573,11.51L.806,9.824c-.007,0-.733-.281-.8-.869s.817-.916.826-.919L19.776.216A2.537,2.537,0,0,1,20.665,0a.6.6,0,0,1,.669.686l-3.373,17.9A1.285,1.285,0,0,1,16.853,19.394ZM17.5,3.677a1.237,1.237,0,0,0-.482.187L6.022,11.21c.018.061.147.506.326,1.122.448,1.544,1.2,4.124,1.395,4.779.052.172.1.32.152.44L8.4,12.9l.019.016.013-.111c.078-.074,8.631-8.164,8.989-8.515.221-.217.26-.329.254-.384a.051.051,0,0,0-.019-.039.19.19,0,0,0-.038-.146A.152.152,0,0,0,17.5,3.677Z"
              transform="translate(0.242 0.485)"
            />
          </clipPath>
        </defs>
        <circle id="Oval_5" cx="16" cy="16" r="16" />
        <g id="Icons_Telegram" transform="translate(3.636 6.545)">
          <path
            id="Shape-2"
            className={styles.innerIcon}
            d="M16.853,19.394a1.6,1.6,0,0,1-.663-.162l-4.8-3.873-3.08,2.954a.563.563,0,0,1-.32.111.431.431,0,0,1-.182-.04s-.23-.028-.511-.965S5.587,11.56,5.573,11.51L.806,9.824c-.007,0-.733-.281-.8-.869s.817-.916.826-.919L19.776.216A2.537,2.537,0,0,1,20.665,0a.6.6,0,0,1,.669.686l-3.373,17.9A1.285,1.285,0,0,1,16.853,19.394ZM17.5,3.677a1.237,1.237,0,0,0-.482.187L6.022,11.21c.018.061.147.506.326,1.122.448,1.544,1.2,4.124,1.395,4.779.052.172.1.32.152.44L8.4,12.9l.019.016.013-.111c.078-.074,8.631-8.164,8.989-8.515.221-.217.26-.329.254-.384a.051.051,0,0,0-.019-.039.19.19,0,0,0-.038-.146A.152.152,0,0,0,17.5,3.677Z"
            transform="translate(0.242 0.485)"
          />
        </g>
      </svg>
    );
  }

  function render0x() {
    return (
      <svg className={styles.zeroX} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <g id="Group_27" transform="translate(-1060 -1955)">
          <circle id="Ellipse_1" cx="16" cy="16" r="16" transform="translate(1060 1955)" />
          <g id="_0x" data-name="0x" transform="translate(1061 1956)">
            <path
              id="Path_1"
              className={styles.innerIcon}
              d="M6.3,18.96,8.63,16.551,5.735,12.644,2.049,7.429A15,15,0,0,0,5.28,26.422l4.679-3.306A9.6,9.6,0,0,1,6.3,18.961ZM11.04,6.3,13.449,8.63l3.907-2.895,5.215-3.686A15,15,0,0,0,3.577,5.28L6.883,9.959A9.6,9.6,0,0,1,11.039,6.3Zm10.33,7.147,2.895,3.907,3.686,5.215A15,15,0,0,0,24.72,3.577L20.04,6.883A9.6,9.6,0,0,1,23.7,11.039l-2.329,2.41ZM26.422,24.72l-3.306-4.679A9.6,9.6,0,0,1,18.961,23.7l-2.41-2.329-3.907,2.895L7.429,27.951A15,15,0,0,0,26.422,24.72Z"
              transform="translate(0)"
            />
          </g>
        </g>
      </svg>
    );
  }

  const elb = t("footer.ethereumLastBlock");
  const telegram = t("footer.telegram", { returnObjects: true });
  const zero = t("footer.0x", { returnObjects: true });
  const dot = t("common.dot");

  return (
    <Div className={styles.footer} darkClassName={styles.footerDark}>
      <div className={styles.footerTitle}>
        <Div className={styles.footerTitleText} darkClassName={styles.footerTitleTextDark}>
          {t("footer.description")}
        </Div>
      </div>
      <div className={styles.content}>
        <Theme darkClassName={styles.contentBlockDark}>
          <div className={styles.contentBlock}>
            <div className={styles.contentImage}>{renderBlockIcon()}</div>
            <div className={styles.contentText}>
              {lastBlock}
              <Div className={styles.contentSubTitle} darkClassName={styles.contentSubTitleDark}>
                {elb.slice(0, 3)}
                <span className={styles.mdHide}>{elb.slice(3, 8)}</span>
                {elb.slice(8)}
              </Div>
            </div>
          </div>
        </Theme>

        <Theme darkClassName={styles.contentBlockDark}>
          <a className={styles.contentBlock} href="https://t.me/odeeofficial">
            <div className={styles.contentImage}>{renderContactIcon()}</div>
            <div className={styles.contentText}>
              {telegram[0]}
              <Div className={styles.contentSubTitle} darkClassName={styles.contentSubTitleDark}>
                {telegram[1]}
              </Div>
            </div>
          </a>
        </Theme>

        <Theme darkClassName={styles.contentBlockDark}>
          <a className={styles.contentBlock} href="https://0xproject.com">
            <div className={styles.contentImage}>{render0x()}</div>
            <div className={styles.contentText}>
              {zero[0]}
              <Div className={styles.contentSubTitle} darkClassName={styles.contentSubTitleDark}>
                {zero[1]}
              </Div>
            </div>
          </a>
        </Theme>
        <Div className={styles.contentLine} darkClassName={styles.contentLineDark} />
      </div>
      <Div className={styles.copyright} darkClassName={styles.copyrightDark}>
        <p>{t("common.copyRight")}</p>
        <p>
          {t("footer.address")}{" "}
          <Text className={styles.link} darkClassName={styles.linkDark} onClick={toggleLicence}>
            {t("footer.licence")}
          </Text>
          {dot}
        </p>
        <p>
          <Text className={styles.link} darkClassName={styles.linkDark} onClick={toggleTermsOfService}>
            {t("footer.tos")}
          </Text>
          {dot}{" "}
          <Text className={styles.link} darkClassName={styles.linkDark} onClick={toggleCookiePolicy}>
            {t("footer.cp")}
          </Text>
          {dot}{" "}
          <Text className={styles.link} darkClassName={styles.linkDark} onClick={togglePrivacyPolicy}>
            {t("footer.pp")}
          </Text>
          {dot}
        </p>
      </Div>
      {licence && <LicenceModal onCloseClick={toggleLicence} />}
      {showPrivacyPolicy && <PrivacyPolicyModal onCloseClick={togglePrivacyPolicy} />}

      {showTermsOfService && <TermsOfServiceModal onCloseClick={toggleTermsOfService} />}

      {showCookiePolicy && <CookiePolicyModal onCloseClick={toggleCookiePolicy} />}
    </Div>
  );
});

const mapProps = state => ({
  rpc: rpcConfigSelector(state)
});

export default connect(mapProps)(Footer);
