import React, { Fragment } from "react";
import { isArray, isPlainObject, isString } from "lodash";
import CustomModal from "../common/CustomModal";
import { withTranslation } from "react-i18next";

export function renderModalData(data) {
  return data.map((item, i) => {
    if (isPlainObject(item)) {
      return (
        <Fragment key={i}>
          <p className="custom-modal__subtitle">{item.subtitle}</p>
          {renderModalData(item.text)}
        </Fragment>
      );
    } else if (isString(item)) {
      return <p className="custom-modal__text" key={i} dangerouslySetInnerHTML={{ __html: item }} />;
    } else if (isArray(item)) {
      return (
        <p className="custom-modal__text" key={i}>
          {item.map((item, i) => {
            return (
              <Fragment key={i}>
                {i > 0 && <br />}
                {item}
              </Fragment>
            );
          })}
        </p>
      );
    }

    return null;
  });
}

class TermsOfServiceModal extends React.Component {
  render() {
    const { onCloseClick, t } = this.props;

    const tos = t("termsOfService.text", { returnObjects: true });

    return (
      <CustomModal onCloseClick={onCloseClick}>
        <div className="d-flex flex-column">
          <h1 className="custom-modal__title">{t("termsOfService.title")}</h1>
          {renderModalData(tos)}
          <div className="d-flex flex-column mt-4">
            <button onClick={onCloseClick} className="custom-modal__close-btn button button--secondary">
              {t("buttons.close")}
            </button>
          </div>
        </div>
      </CustomModal>
    );
  }
}

export default withTranslation()(TermsOfServiceModal);
