import { createSelector } from "reselect";
import { fulfilled, pending, rejected } from "../../helpers/store";
import DataAPI from "../../domain/services/DataAPI";
import { getStatusSelector, isFulfilledOrPendingSelector } from "./status";
import { configSelector } from "./config";
import { walletAddressSelector, walletSelector } from "./wallet";
import BlockChainService from "../../domain/services/BlockChainService";
import BigNumber from "bignumber.js";
import { ASYNC_STATUS } from "../../helpers/constants";

const defaultState = {
  list: [],
  requested: {},
  balances: {}
};

const GET_TOKENS = "unlisted-tokens/get-list";
const GET_TOKENS_PENDING = pending(GET_TOKENS);
const GET_TOKENS_FULFILLED = fulfilled(GET_TOKENS);
const GET_TOKENS_REJECTED = rejected(GET_TOKENS);

const GET_BALANCES = "unlisted-tokens/get-balances";
const GET_BALANCES_PENDING = pending(GET_BALANCES);
const GET_BALANCES_FULFILLED = fulfilled(GET_BALANCES);
const GET_BALANCES_REJECTED = rejected(GET_BALANCES);

const GET_REQUESTED = "unlisted-tokens/get-requested";
const GET_REQUESTED_PENDING = pending(GET_REQUESTED);
const GET_REQUESTED_FULFILLED = fulfilled(GET_REQUESTED);
const GET_REQUESTED_REJECTED = rejected(GET_REQUESTED);

const REQUEST_TOKEN = "unlisted-tokens/request-token";
const REQUEST_TOKEN_PENDING = pending(REQUEST_TOKEN);
const REQUEST_TOKEN_FULFILLED = fulfilled(REQUEST_TOKEN);
const REQUEST_TOKEN_REJECTED = rejected(REQUEST_TOKEN);

export default (state = defaultState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TOKENS_FULFILLED:
      return Object.assign({}, state, { list: payload });
    case GET_BALANCES_FULFILLED:
      return Object.assign({}, state, { balances: payload });
    case GET_REQUESTED_FULFILLED:
      return Object.assign({}, state, { requested: payload });
    case REQUEST_TOKEN_PENDING: {
      const requested = Object.assign({}, state.requested, { [payload]: true });
      return Object.assign({}, state, { requested });
    }
    case REQUEST_TOKEN_REJECTED: {
      const requested = Object.assign({}, state.requested, { [payload]: false });
      return Object.assign({}, state, { requested });
    }
    default:
      return state;
  }
};

export const getUnlistedTokenEffect = () => async (dispatch, getState) => {
  if (isFulfilledOrPendingSelector(getState(), GET_TOKENS)) {
    return;
  }

  dispatch({ type: GET_TOKENS_PENDING });
  try {
    const { data } = await DataAPI.getUnlistedTokens();
    dispatch({ type: GET_TOKENS_FULFILLED, payload: data });
  } catch (e) {
    dispatch({ type: GET_TOKENS_REJECTED, payload: e.message });
  }
};

export const getUnlistedTokensBalancesEffect = () => async (dispatch, getState) => {
  const state = getState();
  const config = configSelector(state);
  const wallet = walletSelector(state);
  const tokens = unlistedTokensSelector(state);

  dispatch({ type: GET_BALANCES_PENDING });
  let bc;
  try {
    bc = new BlockChainService(config, wallet);
    await bc.init();
    bc.start();

    const tokensWithBalances = await bc.getBalances(tokens);
    const balances = tokensWithBalances.reduce((data, { balance, caddress }) => {
      data[caddress] = balance.toFixed();
      return data;
    }, {});
    dispatch({ type: GET_BALANCES_FULFILLED, payload: balances });
  } catch (e) {
    dispatch({ type: GET_BALANCES_REJECTED, payload: e.message });
  } finally {
    if (bc) {
      bc.stop();
    }
  }
};

export const getRequestedUnlistedTokensEffect = () => async (dispatch, getState) => {
  const state = getState();
  const walletAddress = walletAddressSelector(state);

  if (isFulfilledOrPendingSelector(state, GET_REQUESTED)) {
    return;
  }

  dispatch({ type: GET_REQUESTED_PENDING });
  try {
    const {
      data: { message: tokens }
    } = await DataAPI.getRequestedTokens(walletAddress);

    const requested = tokens.reduce((data, { caddress }) => {
      data[caddress] = true;
      return data;
    }, {});
    dispatch({ type: GET_REQUESTED_FULFILLED, payload: requested });
  } catch (e) {
    dispatch({ type: GET_REQUESTED_REJECTED, payload: e.message });
  }
};

export const requestUnlistedTokenEffect = caddress => async (dispatch, getState) => {
  const state = getState();
  const walletAddress = walletAddressSelector(state);

  dispatch({ type: REQUEST_TOKEN_PENDING, payload: caddress });
  try {
    await DataAPI.requestToAddToken(walletAddress, caddress);
    dispatch({ type: REQUEST_TOKEN_FULFILLED });
  } catch (e) {
    dispatch({ type: REQUEST_TOKEN_REJECTED, payload: caddress });
  }
};

export function unlistedTokensSelector(state) {
  return state.unlistedTokens.list;
}

export function unlistedTokensBalancesSelector(state) {
  return state.unlistedTokens.balances;
}

export function requestedUnlistedTokensSelector(state) {
  return state.unlistedTokens.requested;
}

export const myUnlistedTokensSelector = createSelector(
  unlistedTokensSelector,
  unlistedTokensBalancesSelector,
  requestedUnlistedTokensSelector,
  (tokens, balances, requested) => {
    return tokens
      .filter(token => new BigNumber(balances[token.caddress]).isGreaterThan(0))
      .map(token => {
        const { caddress } = token;
        return Object.assign({}, token, {
          balance: balances[caddress],
          requested: requested[caddress]
        });
      });
  }
);

export const unlistedTokensLoadingSelector = state => {
  const tokensStatus = getStatusSelector(state, GET_TOKENS);
  const balancesStatus = getStatusSelector(state, GET_BALANCES);

  return (
    (tokensStatus !== ASYNC_STATUS.FULFILLED || balancesStatus !== ASYNC_STATUS.FULFILLED) &&
    (tokensStatus !== ASYNC_STATUS.REJECTED && balancesStatus !== ASYNC_STATUS.REJECTED)
  );
};
