import CancelToken from "axios/lib/cancel/CancelToken";
import isCancel from "axios/lib/cancel/isCancel";
import { fulfilled, pending, rejected } from "../../helpers/store";
import { get } from "lodash";
import { walletAddressSelector } from "./wallet";
import DataAPI from "../../domain/services/DataAPI";
import { uniqueId } from "../../helpers/string";
import { EXCHANGE_UPDATE_ORDERS, REMOVE_ORDER, UPDATE_ORDER } from "./exchangeUnfilledOrders";
import { ORDER_TYPE } from "../../helpers/constants";

const GET_EXCHANGE_MY_FILLED_ORDERS = "GET_EXCHANGE_MY_FILLED_ORDERS";
const GET_EXCHANGE_MY_FILLED_ORDERS_PENDING = pending(GET_EXCHANGE_MY_FILLED_ORDERS);
const GET_EXCHANGE_MY_FILLED_ORDERS_FULFILLED = fulfilled(GET_EXCHANGE_MY_FILLED_ORDERS);
const GET_EXCHANGE_MY_FILLED_ORDERS_REJECTED = rejected(GET_EXCHANGE_MY_FILLED_ORDERS);

export const ADD_MY_FILLED_ORDER = "@exchange-my-filled-orders/add-my-filled-order";

export default (state = [], action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_EXCHANGE_MY_FILLED_ORDERS_FULFILLED:
      return payload;
    case GET_EXCHANGE_MY_FILLED_ORDERS_PENDING:
      return state;
    case REMOVE_ORDER:
    case UPDATE_ORDER:
      const { myFilledOrders } = payload.exchange;
      return get(myFilledOrders, "length", 0) > 0 ? state.concat(myFilledOrders) : state;
    case EXCHANGE_UPDATE_ORDERS:
      const orders = [];
      Object.values(payload).forEach(({ assetFillAmount, price, type, isMyOrder, isMeTaker }) => {
        if (isMyOrder || isMeTaker) {
          const order = {
            id: uniqueId(),
            amount: assetFillAmount.toString(),
            time: new Date().toUTCString(),
            type,
            price
          };
          if (isMyOrder) {
            orders.push(order);
          }
          if (isMeTaker) {
            orders.push({ ...order, id: uniqueId(), type: type === ORDER_TYPE.BUY ? ORDER_TYPE.SELL : ORDER_TYPE.BUY });
          }
        }
      });
      return state.concat(orders);
    case ADD_MY_FILLED_ORDER:
      return state.concat([payload]);
    default:
      return state;
  }
};

export const addMyFilledOrderAction = order => ({ type: ADD_MY_FILLED_ORDER, payload: order });

let source;
export const getMyFilledOrdersEffect = tradingpairId => async (dispatch, getState) => {
  const walletAddress = walletAddressSelector(getState());
  dispatch({ type: GET_EXCHANGE_MY_FILLED_ORDERS_PENDING });
  if (source) {
    source.cancel();
    source = null;
  }
  if (!source) {
    source = CancelToken.source();
  }

  try {
    const params = {
      tradingpairId,
      status: 2,
      makerAddress: walletAddress,
      takerAddress: walletAddress,
      logicalOperator: 0
    };
    let { data } = await DataAPI.getOrders(params, {
      cancelToken: source.token
    });
    data = data.map(item => ({ ...item, id: uniqueId(), time: item.date }));
    dispatch({ type: GET_EXCHANGE_MY_FILLED_ORDERS_FULFILLED, payload: data });
  } catch (e) {
    if (!isCancel(e)) {
      dispatch({
        type: GET_EXCHANGE_MY_FILLED_ORDERS_REJECTED,
        payload: e.message
      });
    }
  }
};

export const myFilledOrdersSelector = state => state.exchangeMyFilledOrders;
