import React, { memo, useState } from 'react';
import CustomModal from '../common/CustomModal';
import EulaModalContent from './EulaModalContent';
import CookiePolicyContent from '../Footer/CookiePolicyContent';

export default memo(({ onCloseClick }) => {
  const [cookiePolicy, setCookiePolicy] = useState(false);

  function handleCookiePolicyClick() {
    setCookiePolicy(!cookiePolicy);
  }

  function handleUSAClick() {
    let data = {};
    if (window) {
      const { vendor, userAgent, platform, language } = window.navigator;
      const { width, height } = window.screen;
      data = {
        userAgent,
        platform,
        vendor,
        language,
        width,
        height,
        timestamp: Date.now()
      };
    }
    onCloseClick(data);
  }

  function handleCloseClick() {
    if (cookiePolicy) {
      handleCookiePolicyClick();
    }
  }

  return (
    <CustomModal onCloseClick={handleCloseClick} showClose={cookiePolicy}>
      <div>
        {!cookiePolicy ? (
          <EulaModalContent
            onUSAClick={handleUSAClick}
            onCookiePolicyClick={handleCookiePolicyClick}
          />
        ) : (
          <CookiePolicyContent  onBackClick={handleCookiePolicyClick}/>
        )}
      </div>
    </CustomModal>
  );
});
