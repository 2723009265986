import { t } from "../i18n";

export const ERRORS = {
  get MM_NOT_INSTALLED() {
    return t("messages.mmNotInstalled");
  },
  get LEDGER_NOT_SUPPORTED() {
    return t("messages.ledgerNotSupported");
  },
  get WALLET_NOT_SUPPORTED() {
    return t("messages.walletNotSupported");
  },
  get MM_LOGIN_DENIED() {
    return t("messages.deniedLogin");
  },
  get LEDGER_TIMEOUT() {
    return t("messages.timeout");
  },
  get FAILED_LOGIN() {
    return t("messages.loginFailed");
  },
  get ALLOWANCE_REJECTED() {
    return t("messages.rejectedAllowance");
  },
  get ALLOWANCE_NOT_SET() {
    return t("messages.allowanceNotSet");
  },
  get NOT_ENOUGH_BALANCE() {
    return t("messages.noteEnoughBalance");
  },
  get ORDERS_NOT_FILLED() {
    return t("messages.ordersFillFailed");
  },
  get TRANSACTION_FAILED() {
    return t("messages.transactionFailed");
  },
  get NO_ZRX_ORDERS() {
    return t("messages.noZrxOrders");
  },
  get NOT_ENOUGH_ZRX() {
    return t("messages.notEnoughZrx");
  },
  get ALL_ORDERS_FILLED() {
    return t("messages.ordersAlreadyFilled");
  },
  get ORDER_FILLED() {
    return t("messages.orderFiled");
  },
  get FEE_CALC_FAILED() {
    return t("messages.feeCalcFailed");
  },
  get CREATE_ORDER_FAILED() {
    return t("messages.createOrderFailed");
  },
  get INSUFFICIENT_ETH_BALANCE() {
    return t("messages.insufficientEthBalance");
  },
  get GENERIC_ERROR() {
    return t("messages.genericError");
  },
  get NO_FILL_ORDERS() {
    return t("messages.noOrders");
  },
  get NETWORK_NOT_SUPPORTED() {
    return t("messages.networkNotSupported");
  },
  get USER_DENIED_SIGNATURE() {
    return t("messages.userDeniedSignature");
  },
  USER_DENIED_TRANSFER: options => t("messages.userDeniedTransfer", options),
  USER_DENIED_WITHDRAW: options => t("messages.userDeniedWithdraw", options),
  get ETH_APP_ENABLE_CONTRACT_DATA() {
    return t("messages.enableContractData");
  },
  get NOT_ENOUGH_FOR_FEE() {
    return t("messages.notEnoughEthForFee");
  },
  get FETCH_WALLET_BALANCE() {
    return t("messages.walletBalanceFailed");
  },
  FETCH_TOKEN_BALANCE: options => t("messages.tokenBalanceFailed", options),
  INSUFFICIENT_TOKEN_BALANCE: options => t("messages.insufficientTokenBalance", options),
  get ORDER_REJECTED() {
    return t("messages.orderRejected");
  },
  TOKEN_UNLOCK_FAILED: options => t("messages.orderUnlockFailed", options),
  get ADD_TOKEN_FAILED() {
    return t("messages.addTokenFailed");
  }
};

export const SUCCESS = {
  get ORDER_CREATED() {
    return t("messages.orderCreated");
  },
  get FILLED_ORDERS() {
    return t("messages.ordersFilled");
  },
  get SUCCESSFUL_LOGIN() {
    return t("messages.successfulLogin");
  },
  get TRANSFER_SUCCESSFUL() {
    return t("messages.successfulTransfer");
  }
};

export const INFO = {
  get LOCKED_WALLET() {
    return t("messages.lockedWallet", {defaultValue: ""});
  }
};
