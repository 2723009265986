import React, { Fragment, memo } from "react";
import { useParams } from "react-router";
import classNames from "classnames";
import { connect } from "react-redux";
import { NavLink as Link } from "react-router-dom";
import { ReactComponent as AccountIcon } from "../../assets/account.svg";
import routes from "../../helpers/routes";
import ThemedComponent from "../common/ThemedComponent";
import { setUiEffect, themeSelector } from "../../store/modules/ui";
import { ALERT_TYPE, THEME, WALLET_TYPES } from "../../helpers/constants";
import { clearWalletEffect, setWalletEffect, walletAddressSelector } from "../../store/modules/wallet";
import Web3Service from "../../domain/services/Web3Service";
import LoginService from "../../domain/services/LoginService";
import AlertMessage from "../common/AlertMessage/AlertMessage";
import { ERRORS, SUCCESS } from "../../helpers/messages";
import { isMobile } from "../../helpers/window";
import { gtag, GTM_TAGS } from "../../domain/gtm";
import { enabledNetworksSelector } from "../../store/modules/config";
import { useTranslation } from "react-i18next";

const MobileHeader = memo(
  ({ setUi, theme, walletAddress, visible, onHideClick, clearWalletAddress, login, enabledNetworks }) => {
    const { locale } = useParams();
    const { t } = useTranslation();

    function handleToggleTheme() {
      if (theme === THEME.LIGHT) {
        setUi({ theme: THEME.DARK });
      } else {
        setUi({ theme: THEME.LIGHT });
      }
    }

    let address;
    if (walletAddress) {
      address = `${walletAddress.slice(0, 8)}...${walletAddress.slice(-8)}`;
    }

    async function handleUnlockClick(e) {
      if (isMobile() && Web3Service.isInstalled()) {
        e.preventDefault();
        const data = await LoginService.web3Login();
        if (enabledNetworks.includes(data.networkVersion)) {
          login(WALLET_TYPES.WEB3, data);
          AlertMessage.showMessage(SUCCESS.SUCCESSFUL_LOGIN, ALERT_TYPE.SUCCESS);
        } else {
          AlertMessage.showMessage(ERRORS.NETWORK_NOT_SUPPORTED);
        }
        gtag({ event: GTM_TAGS.WALLET_UNLOCK.MM });
        onHideClick();
      } else {
        onHideClick();
      }
    }

    return (
      <ThemedComponent
        component="div"
        className={classNames("mobile-header", {
          "mobile-header--visible": visible
        })}
        darkClassName="mobile-header--dark"
      >
        <button className="mobile-header__close" onClick={onHideClick} />
        <div className="d-flex align-items-center mobile-header__acc">
          <AccountIcon className="mobile-header__acc-icon" />
          {walletAddress ? (
            <span className="mobile-header__wallet">{address.toUpperCase()}</span>
          ) : (
            <span className="mobile-header__wallet">{t("header.guestUser")}</span>
          )}
        </div>
        <div className="mobile-header__separator" />
        <div className="mobile-header__nav">
          <Link
            to={routes.exchange(locale)}
            className="mobile-header__nav-item"
            activeClassName="mobile-header__nav-item--active"
            onClick={onHideClick}
          >
            <div className="os-icon os-icon-layout mobile-header__nav-icon" />
            <span className="mobile-header__nav-label">{t("header.navigation.exchange")}</span>
          </Link>
          {walletAddress && (
            <Fragment>
              <Link
                to={routes.balances(locale)}
                className="mobile-header__nav-item"
                activeClassName="mobile-header__nav-item--active"
                onClick={onHideClick}
              >
                <div className="os-icon os-icon-coins-4 mobile-header__nav-icon" />
                <span className="mobile-header__nav-label">{t("header.navigation.balances")}</span>
              </Link>
              <Link
                to={routes.orders(locale)}
                className="mobile-header__nav-item"
                activeClassName="mobile-header__nav-item--active"
                onClick={onHideClick}
              >
                <div className="os-icon os-icon-file-text mobile-header__nav-icon" />
                <span className="mobile-header__nav-label">{t("header.navigation.orders")}</span>
              </Link>
            </Fragment>
          )}
          <button className="mobile-header__nav-item" onClick={handleToggleTheme}>
            <div className="os-icon os-icon-layers mobile-header__nav-icon" />
            <span className="mobile-header__nav-label">{t("header.navigation.theme")}</span>
          </button>

          {/*<Link*/}
          {/*  to={routes.help()}*/}
          {/*  className="mobile-header__nav-item"*/}
          {/*  activeClassName="mobile-header__nav-item--active"*/}
          {/*  onClick={onHideClick}*/}
          {/*>*/}
          {/*  <div className="os-icon os-icon-life-buoy mobile-header__nav-icon" />*/}
          {/*  <span className="mobile-header__nav-label">Help</span>*/}
          {/*</Link>*/}

          <div>
            <label className="mobile-header__nav-item flex-column align-items-start">
              <div className="d-flex align-items-center">
                <div className="os-icon os-icon-life-buoy mobile-header__nav-icon" />
                <span className="mobile-header__nav-label">{t("header.navigation.support")}</span>
              </div>

              <input type="checkbox" className="mobile-header__sub-check" />
              <div className="mobile-header__sub">
                <Link
                  to={routes.help(locale)}
                  className="mobile-header__nav-item"
                  activeClassName="mobile-header__nav-item--active"
                  onClick={onHideClick}
                >
                  <span className="mobile-header__nav-label">{t("header.navigation.help")}</span>
                </Link>
                <Link
                  to={routes.faq(locale)}
                  className="mobile-header__nav-item"
                  activeClassName="mobile-header__nav-item--active"
                  onClick={onHideClick}
                >
                  <span className="mobile-header__nav-label">{t("header.navigation.faq")}</span>
                </Link>
                <Link
                  to={routes.apiDoc(locale)}
                  className="mobile-header__nav-item"
                  activeClassName="mobile-header__nav-item--active"
                  onClick={onHideClick}
                >
                  <span className="mobile-header__nav-label">{t("header.navigation.api")}</span>
                </Link>
              </div>
            </label>
          </div>

          {walletAddress ? (
            <button className="mobile-header__nav-item" onClick={clearWalletAddress}>
              <div className="os-icon os-icon-log-out mobile-header__nav-icon" />
              <span className="mobile-header__nav-label">{t("header.navigation.logout")}</span>
            </button>
          ) : (
            <Link
              to={routes.wallet(locale)}
              className="mobile-header__nav-item"
              activeClassName="mobile-header__nav-item--active"
              onClick={handleUnlockClick}
            >
              <div className="os-icon os-icon-wallet-loaded mobile-header__nav-icon" />
              <span className="mobile-header__nav-label">{t("header.navigation.unlockWallet")}</span>
            </Link>
          )}
        </div>
        <div className="mobile-header__separator" />
        <p className="mobile-header__footer">{t("common.copyRight")}</p>
      </ThemedComponent>
    );
  }
);

const mapState = state => ({
  theme: themeSelector(state),
  walletAddress: walletAddressSelector(state),
  enabledNetworks: enabledNetworksSelector(state)
});

const mapDispatch = dispatch => ({
  setUi: data => dispatch(setUiEffect(data)),
  clearWalletAddress: () => dispatch(clearWalletEffect()),
  login: (type, data) => dispatch(setWalletEffect(type, data))
});

export default connect(mapState, mapDispatch)(MobileHeader);
