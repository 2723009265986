import { DevUtilsContract as DUC } from "@0x/contract-wrappers";
import { getContractAddressesForChainOrThrow } from "@0x/contract-addresses";
import { isEqual } from "lodash";

export default class DevUtilsContract {
  static _value;
  static _inputs;

  constructor(networkVersion, provider) {
    const inputs = { networkVersion, provider };
    if (isEqual(DevUtilsContract._inputs, inputs)) {
      return DevUtilsContract._value;
    }

    const contractAddresses = getContractAddressesForChainOrThrow(networkVersion);
    const devUtilsContract = new DUC(contractAddresses.devUtils, provider);

    DevUtilsContract._value = devUtilsContract;
    DevUtilsContract._inputs = inputs;

    return devUtilsContract;
  }
}
