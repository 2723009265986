import { orderCalculationUtils } from "@0x/order-utils";
import { BigNumber } from "bignumber.js";
import { ORDER_TYPE } from "../constants";
import { convertOrderStringFieldsToBigNumber } from "../orders";

const { getMakerFillAmount, getTakerFillAmount, getMakerFeeAmount, getTakerFeeAmount } = orderCalculationUtils;

export function getOrderWithLeftAmount(order) {
  const {
    type,
    amountleft,
    takerAssetAmount,
    makerAssetAmount,
    takerFee,
    makerFee
  } = convertOrderStringFieldsToBigNumber(order);

  if (type === ORDER_TYPE.BUY) {
    const amountLeft = amountleft ? new BigNumber(amountleft) : takerAssetAmount;
    const makerAssetAmountLeft = getMakerFillAmount(order, amountLeft);
    const makerFeeLeft = getMakerFeeAmount(order, makerAssetAmount);

    return {
      ...order,
      amountLeft,
      takerAssetAmountLeft: amountLeft,
      makerAssetAmountLeft,
      makerFeeLeft,
      takerFeeLeft: takerFee
    };
  } else {
    const amountLeft = amountleft ? new BigNumber(amountleft) : makerAssetAmount;
    const takerAssetAmountLeft = getTakerFillAmount(order, amountLeft);
    const takerFeeLeft = getTakerFeeAmount(order, takerAssetAmountLeft);

    return {
      ...order,
      amountLeft,
      makerAssetAmountLeft: amountLeft,
      takerAssetAmountLeft,
      takerFeeLeft,
      makerFeeLeft: makerFee
    };
  }
}
