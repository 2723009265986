import axios from "axios";
import { get } from "lodash";
import { fulfilled, generateStatuses, pending, rejected } from "../../helpers/store";
import { getStatusSelector } from "./status";
import { CONFIG_URL, ASYNC_STATUS } from "../../helpers/constants";
import Api from "../../domain/services/Api";
import { networkVersionSelector } from "./wallet";
import { createSelector } from "reselect";

const GET_CONFIG = "GET_CONFIG";
const GET_CONFIG_PENDING = pending(GET_CONFIG);
const GET_CONFIG_FULFILLED = fulfilled(GET_CONFIG);
const GET_CONFIG_REJECTED = rejected(GET_CONFIG);

export default (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CONFIG_FULFILLED:
      return payload;
    default:
      return state;
  }
};

export const getConfigEffect = () => async dispatch => {
  dispatch({ type: GET_CONFIG_PENDING });
  try {
    const { data } = await axios.get(CONFIG_URL);
    Api.defaults.baseURL = data.api;
    dispatch({ type: GET_CONFIG_FULFILLED, payload: data });
  } catch (e) {
    dispatch({ type: GET_CONFIG_REJECTED, payload: e });
  }
};

export function configSelector(state) {
  return state.config;
}

export function configWithExtraSelector(state) {
  return {
    config: state.config,
    ...generateStatuses(getStatusSelector(state, GET_CONFIG))
  };
}

export function hasConfigSelector(state) {
  return !!configSelector(state);
}

export const rpcsConfigSelector = createSelector(configSelector, config => {
  if (!config) {
    return {};
  }

  const { rpcHost, rpcPort, rpcHostKovan, rpcPortKovan } = config;
  return {
    1: `${rpcHost}${rpcPort ? `:${rpcPort}` : ""}`,
    42: `${rpcHostKovan}${rpcPortKovan ? `:${rpcPortKovan}` : ""}`
  };
});

export function rpcConfigSelector(state) {
  const networkVersion = networkVersionSelector(state);
  return (rpcsConfigSelector(state) || {})[networkVersion];
}

export function websocketConfigSelector(state) {
  const config = configSelector(state);
  return get(config, "websocket");
}

export const feeRecipientSelector = createSelector(configSelector, config => get(config, "feeRecipient"));

export function apiConfigSelector(state) {
  const config = configSelector(state);
  return get(config, "api");
}

export const enabledNetworksSelector = createSelector(configSelector, config => get(config, "enabledNetworks", []));

export const defaultNetworkSelector = state => get(configSelector(state), "defaultNetwork");

export const configFulfilledStatus = state => getStatusSelector(state, GET_CONFIG) === ASYNC_STATUS.FULFILLED;
