import { BigNumber } from "bignumber.js";
import { Web3Wrapper } from "@0x/web3-wrapper/lib/src/web3_wrapper";
import { orderCalculationUtils } from "@0x/order-utils";
import { ORDER_TYPE } from "./constants";

const { getMakerFillAmount, getMakerFeeAmount, getTakerFeeAmount, getTakerFillAmount } = orderCalculationUtils;

export function convertToBigNumber(amount) {
  if (amount instanceof BigNumber) {
    return amount;
  }
  return new BigNumber(amount);
}

export function toUnitAmount(amount, decimals, canBeNegative = false) {
  let number = convertToBigNumber(amount);
  number = new BigNumber(number.toFixed(0));

  if (canBeNegative) {
    let isNegative = number.isNegative();
    let unitAmount = Web3Wrapper.toUnitAmount(number.absoluteValue(), decimals);
    if (isNegative) {
      unitAmount = unitAmount.negated();
    }
    return unitAmount;
  }

  return Web3Wrapper.toUnitAmount(number, decimals);
}

export function toUnitAmountV2(amount, token, ...args) {
  const { nodecimals } = token;
  return toUnitAmount(amount, nodecimals, ...args);
}

export function toBaseUnitAmount(amount, decimals) {
  let number = convertToBigNumber(new BigNumber(amount).toFixed(decimals));
  return Web3Wrapper.toBaseUnitAmount(number, decimals);
}

export function toBaseUnitAmountV2(amount, token) {
  const { nodecimals } = token;
  return toBaseUnitAmount(amount, nodecimals);
}

export function mapOrder(order) {
  const { type, amountleft, takerAssetAmount, makerAssetAmount, takerFee, makerFee } = order;

  if (type === ORDER_TYPE.BUY) {
    const amountLeft = amountleft || takerAssetAmount;
    const makerAssetAmountLeft = getMakerFillAmount(order, new BigNumber(amountLeft));
    const makerFeeLeft = getMakerFeeAmount(order, new BigNumber(makerAssetAmount));

    return {
      ...order,
      amountLeft,
      takerAssetAmountLeft: amountLeft,
      makerAssetAmountLeft,
      makerFeeLeft,
      takerFeeLeft: takerFee
    };
  } else if (type === ORDER_TYPE.SELL) {
    const amountLeft = amountleft || makerAssetAmount;
    const takerAssetAmountLeft = getTakerFillAmount(order, new BigNumber(amountLeft));
    const takerFeeLeft = getTakerFeeAmount(order, new BigNumber(takerAssetAmountLeft));

    return {
      ...order,
      amountLeft,
      makerAssetAmountLeft: amountLeft,
      takerAssetAmountLeft,
      takerFeeLeft,
      makerFeeLeft: makerFee
    };
  }
  return order;
}

export function convertOrderStringFieldsToBigNumber(order) {
  return Object.entries(order).reduce((bnOrder, [key, value]) => {
    bnOrder[key] = typeof value === "string" ? convertToBigNumber(value) : value;
    return bnOrder;
  }, {});
}
