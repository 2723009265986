import Web3 from "web3";
import { toNumber } from "lodash";
import { ERRORS } from "../../helpers/messages";
import Web3Utils from "../trade/Web3Utils";

export default {
  async web3Login() {
    if (!Web3Utils.isInstalled) {
      throw new Error(ERRORS.MM_NOT_INSTALLED);
    }
    const address = await Web3Utils.getAccount();
    const networkVersion = await Web3Utils.networkVersion();
    return {
      address: address.toLowerCase(),
      networkVersion: toNumber(networkVersion)
    };
  },
  privateKeyLogin(provider, privateKey) {
    const web3 = new Web3(provider);
    const data = web3.eth.accounts.privateKeyToAccount(privateKey);
    const { address } = data;
    return {
      address,
      privateKey: privateKey.slice(2)
    };
  },
  keystoreLogin(provider, keystore, password) {
    const web3 = new Web3(provider);
    const data = web3.eth.accounts.decrypt(keystore, password);
    return {
      address: data.address,
      privateKey: data.privateKey.slice(2)
    };
  }
};
