import { memo, useCallback, useEffect, useRef, useState } from "react";
import { toNumber } from "lodash";
import { connect } from "react-redux";
import { configWithExtraSelector, enabledNetworksSelector } from "../store/modules/config";
import { clearWalletEffect, setWalletEffect, walletSelector } from "../store/modules/wallet";
import { ALERT_TYPE, WALLET_TYPES } from "../helpers/constants";
import useEffectNotNil from "../hooks/useEffectNotNil";
import Web3Utils from "../domain/trade/Web3Utils";
import AlertMessage from "./common/AlertMessage/AlertMessage";
import { ERRORS, INFO } from "../helpers/messages";
import { isMobile } from "../helpers/window";
import Storage from "../domain/services/Storage";

const Web3Listener = memo(
  /** @return {null} */
  function Web3Listener({ wallet, enabledNetworks, login, logout, configWithExtra }) {
    const interval = useRef();
    const [unlocked, setUnlocked] = useState(false);
    const { current: provider } = useRef(Web3Utils.provider);
    const { type, networkVersion, address, unlocked: walletUnlocked } = wallet || {};
    const { fulfilled } = configWithExtra;

    const handleAccountsChanged = useCallback(
      ([newAddress]) => {
        if (newAddress !== address) {
          if (newAddress) {
            Storage.setWallet({ ...Storage.getWallet({}), address: newAddress });
          }
          window.location.reload();
        }
      },
      [address]
    );

    const handleNetworkChanged = useCallback(
      newNetworkVersion => {
        if (newNetworkVersion !== networkVersion) {
          if (newNetworkVersion) {
            Storage.setWallet({ ...Storage.getWallet({}), networkVersion: toNumber(newNetworkVersion) });
          }
          window.location.reload();
        }
      },
      [networkVersion]
    );

    const web3Login = useCallback(async () => {
      try {
        if (Web3Utils.isInstalled) {
          const isLocked = await Web3Utils.isLocked();
          if (isLocked && INFO.LOCKED_WALLET) {
            AlertMessage.showMessage(INFO.LOCKED_WALLET, ALERT_TYPE.INFO);
          }
          const newAddress = await Web3Utils.getAccount();
          const newNetworkVersion = await Web3Utils.networkVersion();

          setUnlocked(true);
          if (newNetworkVersion !== networkVersion || newAddress !== address || walletUnlocked !== true) {
            login({ address: newAddress, networkVersion: newNetworkVersion });
          }
          if (newNetworkVersion !== networkVersion) {
            window.location.reload();
          }
        } else if (!type) {
          logout();
        }
      } catch (e) {
        AlertMessage.showMessage(e.message);
        logout();
      }
    }, [login, logout, type, networkVersion, address, setUnlocked, walletUnlocked]);

    const watchChange = useCallback(async () => {
      const locked = await Web3Utils.isLocked();
      if (locked) {
        logout();
      }
    }, [logout]);

    useEffect(() => {
      if (!isMobile() && type === WALLET_TYPES.WEB3) {
        web3Login();
      }
      if (!type) {
        setUnlocked(false);
      }
    }, [type, web3Login, setUnlocked]);

    useEffect(() => {
      if (isMobile()) {
        web3Login();
      }
    }, [web3Login]);

    useEffectNotNil(() => {
      if (unlocked && type === WALLET_TYPES.WEB3 && Web3Utils.isInstalled) {
        if (provider && provider.addListener) {
          provider.addListener("accountsChanged", handleAccountsChanged);
          provider.addListener("networkChanged", handleNetworkChanged);
          interval.current = setInterval(watchChange, 1000);
        }
        return () => {
          if (provider && provider.removeListener) {
            provider.removeListener("accountsChanged", handleAccountsChanged);
            provider.removeListener("networkChanged", handleNetworkChanged);
            clearInterval(interval.current);
          }
        };
      }
    }, [type, handleAccountsChanged, handleNetworkChanged, watchChange, unlocked]);

    useEffectNotNil(() => {
      if (fulfilled && !enabledNetworks.includes(networkVersion)) {
        AlertMessage.showMessage(ERRORS.NETWORK_NOT_SUPPORTED, ALERT_TYPE.INFO);
      }
    }, [enabledNetworks, networkVersion, fulfilled]);

    return null;
  }
);

const mapState = state => ({
  configWithExtra: configWithExtraSelector(state),
  enabledNetworks: enabledNetworksSelector(state),
  wallet: walletSelector(state)
});

const mapDispatch = dispatch => ({
  login: data => dispatch(setWalletEffect(WALLET_TYPES.WEB3, data)),
  logout: () => dispatch(clearWalletEffect())
});

export default connect(mapState, mapDispatch)(Web3Listener);
