import { ContractWrappers as CW } from "@0x/contract-wrappers";
import { isEqual } from "lodash";

export default class ContractWrappers {
  static _value;
  static _inputs;

  constructor(provider, chainId) {
    const inputs = { provider, chainId };
    if (isEqual(ContractWrappers._inputs, inputs)) {
      return ContractWrappers._value;
    }

    const cw = new CW(provider, { chainId });
    ContractWrappers._value = cw;
    ContractWrappers._inputs = inputs;

    return cw;
  }
}
