import React, { PureComponent, createRef } from "react";
import get from "lodash/get";
import classNames from "classnames";
import styles from "./AlertMessage.module.scss";
import store from "../../../store";
import {ALERT_TYPE, THEME} from '../../../helpers/constants';

const IN_DURATION = 250;
const OUT_DURATION = 1000;
const HIDE_DELAY = 2000;

export default class AlertMessage extends PureComponent {
  static getTheme = () => get(store.getState(), ["ui", "theme"]);
  static ref = createRef();
  static q = [];

  static showMessage = (msg, type = ALERT_TYPE.ERROR) => {
    try {
      if (!AlertMessage.ref.current) {
        return;
      }

      AlertMessage.q.push(() => {
        AlertMessage.show(msg, type, msgEl => {
          AlertMessage.dismiss(msgEl, type, () => {
            AlertMessage.q.shift();
            const nextMsg = AlertMessage.q[0];
            if (nextMsg) {
              nextMsg();
            }
          });
        });
      });

      if (AlertMessage.q.length === 1) {
        AlertMessage.q[0]();
      }
    } catch (e) {}
  };

  static show = (msg, type, cb) => {
    const el = AlertMessage.ref.current;
    if (!el) {
      return;
    }

    let timeout = null;
    const msgEl = document.createElement("span");
    function handleMouseover() {
      clearTimeout(timeout);
      msgEl.removeEventListener("mouseover", handleMouseover);
    }

    function handleMouseout() {
      cb(msgEl);
      msgEl.removeEventListener("mouseout", handleMouseout);
      msgEl.removeEventListener("click", handleClick);
    }

    function handleClick() {
      cb(msgEl);
      msgEl.removeEventListener("click", handleClick);
      msgEl.removeEventListener("mouseout", handleMouseout);
    }

    msgEl.innerText = msg;
    msgEl.className = AlertMessage.getStyles(type);
    msgEl.addEventListener("mouseover", handleMouseover);
    msgEl.addEventListener("mouseout", handleMouseout);
    msgEl.addEventListener("click", handleClick);
    el.appendChild(msgEl);
    timeout = setTimeout(() => cb(msgEl), HIDE_DELAY + IN_DURATION);
  };

  static dismiss = (msgEl, type, cb) => {
    const el = AlertMessage.ref.current;
    if (el && !el.contains(msgEl)) {
      return;
    }

    msgEl.className = AlertMessage.getStyles(type, true);
    setTimeout(() => {
      if (el && el.contains(msgEl)) {
        el.removeChild(msgEl);
      }

      if (cb) {
        cb();
      }
    }, OUT_DURATION);
  };

  static getStyles = (type, hide = false) => {
    const theme = AlertMessage.getTheme();
    const stylesArr = [styles.alertMessage];
    if (hide) {
      stylesArr.push(styles.alertMessageHide);
    } else {
      stylesArr.push(styles.alertMessageShow);
    }

    switch (type) {
      case ALERT_TYPE.INFO:
        stylesArr.push(styles.alertMessageInfo);
        if (theme === THEME.DARK) {
          stylesArr.push(styles.alertMessageInfoDark);
        }
        break;
      case ALERT_TYPE.ERROR:
        stylesArr.push(styles.alertMessageError);
        break;
      case ALERT_TYPE.SUCCESS:
        stylesArr.push(styles.alertMessageSuccess);
        break;
      default:
    }

    return classNames(...stylesArr);
  };

  render() {
    return <div ref={AlertMessage.ref} className={styles.alertBox} />;
  }
}
