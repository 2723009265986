import { RPCSubprovider, Web3ProviderEngine } from "0x.js";
import {
  MetamaskSubprovider,
  SignerSubprovider,
  ledgerEthereumBrowserClientFactoryAsync,
  LedgerSubprovider,
  PrivateKeyWalletSubprovider
} from "@0x/subproviders";
import { isEqual } from "lodash";
import Web3Utils from "../trade/Web3Utils";
import { WALLET_TYPES } from "../../helpers/constants";
import { ERRORS } from "../../helpers/messages";

export default class Provider {
  static _value;
  static _inputs;

  static async init(type, networkId, rpc, privateKey) {
    const inputs = { type, networkId, rpc, privateKey };
    if (isEqual(Provider._inputs, inputs)) {
      return Provider._value;
    }

    let subprovider = null;
    switch (type) {
      case WALLET_TYPES.WEB3:
        if (Web3Utils.isMetaMask) {
          subprovider = new MetamaskSubprovider(Web3Utils.provider);
        } else {
          subprovider = new SignerSubprovider(Web3Utils.provider);
        }
        break;
      case WALLET_TYPES.LEDGER:
        subprovider = new LedgerSubprovider({
          networkId,
          ledgerEthereumClientFactoryAsync: ledgerEthereumBrowserClientFactoryAsync
        });
        break;
      case WALLET_TYPES.PRIVATE_KEY:
      case WALLET_TYPES.KEYSTORE_FILE:
        subprovider = new PrivateKeyWalletSubprovider(privateKey);
        break;
      default:
        throw new Error(ERRORS.WALLET_NOT_SUPPORTED);
    }
    const provider = new Web3ProviderEngine();
    provider.addProvider(subprovider);
    provider.addProvider(new RPCSubprovider(rpc));

    Provider._value = provider;
    Provider._inputs = inputs;

    return provider;
  }
}
