import Storage from "../../domain/services/Storage";
import {THEME} from '../../helpers/constants';

const SET_UI = "SET_UI";

const defaultState = () => {
  const ui = Storage.getUi();
  if (ui) {
    return ui;
  }

  return {
    charts: true,
    balances: true,
    orders: true,
    trades: true,
    historyedit: true,
    theme: THEME.DARK,
    buyGrouping: true,
    buyDecimals: 4,
    sellGrouping: true,
    sellDecimals: 4,
  };
};

export default (state = defaultState(), action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_UI:
      return Object.assign({}, state, payload);
    default:
      return state;
  }
};

export const uiSelector = state => state.ui;

export const setUiEffect = data => (dispatch, getState) => {
  dispatch({ type: SET_UI, payload: data });
  const ui = uiSelector(getState());
  Storage.setUi(ui);
};

export const setUiViewAllEffect = () => dispatch => {
  const data = {
    charts: true,
    balances: true,
    orders: true,
    trades: true,
    historyedit: true
  };
  dispatch(setUiEffect(data));
};

export const setUiViewBasicEffect = () => dispatch => {
  const data = {
    charts: false,
    balances: false,
    orders: false,
    trades: false,
    historyedit: false
  };
  dispatch(setUiEffect(data));
};

export const themeSelector = state => uiSelector(state).theme;
