import React from 'react';
import CustomModal from '../common/CustomModal';
import CookiePolicyContent from './CookiePolicyContent';

export default class CookiePolicyModal extends React.Component {
  render() {
    const { onCloseClick } = this.props;

    return (
      <CustomModal onCloseClick={onCloseClick}>
        <CookiePolicyContent onCloseClick={onCloseClick}/>
      </CustomModal>
    );
  }
}
