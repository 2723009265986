import { useEffect, useRef } from "react";

function isDefined(data) {
  return data !== undefined && data !== null;
}

export default (fn, inputs) => {
  const fncRef = useRef();
  fncRef.current = fn;

  useEffect(
    () => {
      if (inputs.every(isDefined)) {
        return fncRef.current();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    inputs
  );
};
