import React, { memo } from "react";
import { Redirect } from "react-router";
import routes, { paths } from "../helpers/routes";
import { get } from "lodash";
import { Route } from "react-router-dom";

const CustomRedirect = () => {
  return (
    <Route path={paths.root} render={props => <Redirect to={routes.exchange(get(props, "match.params.locale"))} />} />
  );
};

export default memo(CustomRedirect);
