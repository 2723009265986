import { isMobile as isM, isTablet } from "react-device-detect";
import { parse } from "query-string";

export function isMobile() {
  return isM || isTablet || isIOS();
}

function isIOS() {
  return (
    /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  );
}

export function detectOs() {
  let osName = "Unknown OS";

  if (navigator.appVersion.indexOf("Win") !== -1) osName = "Windows";
  if (navigator.appVersion.indexOf("Mac") !== -1) osName = "MacOS";
  if (navigator.appVersion.indexOf("X11") !== -1) osName = "UNIX";
  if (navigator.appVersion.indexOf("Linux") !== -1) osName = "Linux";
  if (navigator.appVersion.indexOf("Android") !== -1) osName = "Android";
  if (navigator.appVersion.indexOf("iPhone") !== -1) osName = "iPhone";

  return osName;
}

export function getLocationQueryParam(param, defaultValue) {
  const params = parse(window.location.search);
  return params[param] || defaultValue;
}
