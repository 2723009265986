import { get } from "lodash";

const { web3, ethereum } = window;

if (ethereum) {
  ethereum.autoRefreshOnNetworkChange = false;
}

function getProvider() {
  return ethereum || get(web3, "currentProvider");
}

export default {
  isInstalled() {
    return typeof ethereum !== "undefined" || typeof web3 !== "undefined";
  },
  isMetaMask: get(getProvider(), "isMetaMask", false),
  isTrust: get(getProvider(), "isTrust", false),
  isCoinbaseWallet: get(getProvider(), "isCoinbaseWallet", false),
  isCipher: get(getProvider(), "isCipher", false),
  getProvider
};
