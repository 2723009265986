import React, { memo, Fragment, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MobileHeader from "./MobileHeader";
import HeaderDesk from "./HeaderView";
import useScrollDirection from "../../hooks/useScrollDirection";

const Header = memo(() => {
  const [mobile, setMobile] = useState(false);
  const matches = useMediaQuery("(max-width:576px)");
  const direction = useScrollDirection();

  function handleMenuClick() {
    setMobile(!mobile);
  }

  return (
      <Fragment>
        <HeaderDesk onMenuClick={handleMenuClick} direction={matches ? direction : undefined} />
        {matches && <MobileHeader visible={mobile} onHideClick={handleMenuClick} />}
      </Fragment>
  );
});

export default Header;
