import React from 'react';
import {omit} from 'lodash'
import {THEME} from '../../helpers/constants';
import withTheme from '../../hoc/withTheme';

function ThemedComponent({theme, component, lightClassName, darkClassName, className, ...props}) {
  let concatClassName = '';
  if (className) {
    concatClassName = className;
  }
  if (theme === THEME.LIGHT && lightClassName) {
    concatClassName += ` ${lightClassName}`;
  }
  if (theme === THEME.DARK && darkClassName) {
    concatClassName += ` ${darkClassName}`;
  }
  concatClassName = concatClassName.trim();

  return React.createElement(component, Object.assign({}, omit(props, ['setTheme']), {className: concatClassName || undefined}));
}

export default withTheme(ThemedComponent);
