import React from "react";
import hoistNonReactStatic from "hoist-non-react-statics";
import useTheme from "../hooks/useTheme";

export default function withTheme(WrappedComponent) {
  function Enhance({ forwardedRef, ...props }) {
    const [theme, setTheme] = useTheme();

    return <WrappedComponent theme={theme} setTheme={setTheme} ref={forwardedRef} {...props} />;
  }

  hoistNonReactStatic(Enhance, WrappedComponent);
  return React.forwardRef((props, ref) => <Enhance {...props} forwardedRef={ref} />);
}
