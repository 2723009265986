import { fulfilled, pending, rejected } from "../../helpers/store";
import { networkVersionSelector, updateWalletBalanceAction, walletSelector } from "./wallet";
import { configSelector } from "./config";
import BigNumber from "bignumber.js";
import { toBaseUnitAmount, toUnitAmount } from "../../helpers/trade/amountConverters";
import AlertMessage from "../../components/common/AlertMessage/AlertMessage";
import DataAPI from "../../domain/services/DataAPI";
import { ETH_DECIMALS, NULL_ADDRESS, TRANSACTION_TYPE } from "../../helpers/constants";
import BlockChainService from "../../domain/services/BlockChainService";
import { checkEnabledContractData } from "../../helpers/trade/marketUtils";
import { setBalanceAction, wethTokenSelector } from "../modules-v2/tokens";

const GET_DEPOSIT_WITHDRAW = "GET_DEPOSIT_WITHDRAW";
const GET_DEPOSIT_WITHDRAW_PENDING = pending(GET_DEPOSIT_WITHDRAW);
const GET_DEPOSIT_WITHDRAW_FULFILLED = fulfilled(GET_DEPOSIT_WITHDRAW);
const GET_DEPOSIT_WITHDRAW_REJECTED = rejected(GET_DEPOSIT_WITHDRAW);

const ADD_DEPOSIT_WITHDRAW = "ADD_DEPOSIT_WITHDRAW";
const ADD_DEPOSIT_WITHDRAW_FULFILLED = fulfilled(ADD_DEPOSIT_WITHDRAW);

export default (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DEPOSIT_WITHDRAW_FULFILLED:
      return payload;
    case ADD_DEPOSIT_WITHDRAW_FULFILLED:
      if (payload) {
        return state.concat([payload]);
      }
      return state;
    default:
      return state;
  }
};

export const getDepositWithdrawEffect = () => async (dispatch, getState) => {
  const state = getState();
  const wallet = walletSelector(state);
  const netId = networkVersionSelector(state);
  dispatch({ type: GET_DEPOSIT_WITHDRAW_PENDING });
  try {
    const { data } = await DataAPI.getTransactions(wallet.address, netId);
    dispatch({ type: GET_DEPOSIT_WITHDRAW_FULFILLED, payload: data });
  } catch (e) {
    dispatch({ type: GET_DEPOSIT_WITHDRAW_REJECTED, payload: e.message });
  }
};

export const withdrawEffect = modal => async (dispatch, getState) => {
  const state = getState();
  const config = configSelector(state);
  const wallet = walletSelector(state);
  const wethToken = wethTokenSelector(state);
  const amount = toBaseUnitAmount(new BigNumber(wethToken.balance || 0).minus(wethToken.inOpenOrders || 0), wethToken);

  let bc;
  try {
    bc = new BlockChainService(config, wallet);
    await bc.init();
    bc.start();
    await checkEnabledContractData(modal, wallet.type);
    const withdraw = await bc.withdraw(amount);
    const [gas, wb] = await Promise.all([withdraw.estimateGas(), bc.getEthBalance()]);
    if (wb.isLessThan(gas)) {
      modal.showWithdrawFee();
    } else {
      modal.startWithdraw();
      const th = await withdraw.sendTransaction();
      modal.next({ time: bc.waitTime() });
      await bc.awaitTransactionSuccess(th);
      modal.next();
      setTimeout(() => {
        modal.close();
      }, 3000);
      const [[, walletBalance], [, wethBalance]] = await Promise.all([
        bc.getEthBalanceNoThrow(),
        bc.getBalanceNoThrow(wethToken)
      ]);
      if (walletBalance) {
        dispatch(updateWalletBalanceAction(toUnitAmount(walletBalance, ETH_DECIMALS)));
      }
      if (wethBalance) {
        dispatch(setBalanceAction(wethToken.address, toUnitAmount(wethBalance, wethToken)));
      }
      const {
        data: { message }
      } = await DataAPI.createTransaction({
        tokenAddress: wethToken.address,
        senderAddress: wallet.address,
        receiverAddress: NULL_ADDRESS,
        type: TRANSACTION_TYPE.WITHDRAW,
        amount: amount.toFixed(),
        transactionId: th
      });
      dispatch({ type: ADD_DEPOSIT_WITHDRAW_FULFILLED, payload: message });
    }
  } catch (e) {
    console.log(e);
    modal.close();
    AlertMessage.showMessage(e.message);
  } finally {
    if (bc) {
      bc.stop();
    }
  }
};

export const depositWithdrawSelector = state => state.depositWithdraw;
