const WALLET_KEY = "wallet";
const FAV_TOKENS = "fav-tokens";
const SELECTED_PAIR = "selected-pair";
const UI = "ui";
const APP_VER = "app-ver";

function setToStorage(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

function getFromStorage(key, defaultValue) {
  const data = window.localStorage.getItem(key);
  return JSON.parse(data) || defaultValue;
}

function removeFromStorage(key) {
  window.localStorage.removeItem(key);
}

export default {
  setAppVer(ver) {
    window.localStorage.setItem(APP_VER, ver);
  },
  getAppVer() {
    return window.localStorage.getItem(APP_VER);
  },
  setWallet(data) {
    setToStorage(WALLET_KEY, data);
  },
  removeWallet() {
    removeFromStorage(WALLET_KEY);
  },
  getWallet(defaultValue) {
    return getFromStorage(WALLET_KEY) || defaultValue;
  },
  getFavTokens(defaultValue) {
    return getFromStorage(FAV_TOKENS, defaultValue);
  },
  removeFavToken(caddress) {
    const tokens = getFromStorage(FAV_TOKENS) || [];
    const newTokens = tokens.filter(token => token !== caddress);
    setToStorage(FAV_TOKENS, newTokens);
    return newTokens;
  },
  addFavToken(caddress) {
    const tokens = getFromStorage(FAV_TOKENS) || [];
    tokens.push(caddress);
    setToStorage(FAV_TOKENS, tokens);
    return tokens;
  },
  clearFavTokens() {
    removeFromStorage(FAV_TOKENS);
  },
  removeSelectedToken() {
    removeFromStorage(SELECTED_PAIR);
  },
  setSelectedPair(caddress) {
    setToStorage(SELECTED_PAIR, caddress);
  },
  getPairToken(defaultValue) {
    return getFromStorage(SELECTED_PAIR, defaultValue);
  },
  getUi() {
    return getFromStorage(UI);
  },
  setUi(data) {
    setToStorage(UI, data);
  }
};
