import { Web3Wrapper as W3W } from "@0x/web3-wrapper";

export default class Web3Wrapper {
  static _value;
  static _inputs;

  constructor(provider) {
    if (Web3Wrapper._inputs === provider) {
      return Web3Wrapper._value;
    }

    const wrapper = new W3W(provider);
    Web3Wrapper._value = wrapper;
    Web3Wrapper._inputs = provider;

    return wrapper;
  }
}
