import { useState, useCallback } from "react";

const useToggleState = (defaultState = false) => {
  const [state, setState] = useState(defaultState);

  const toggleState = useCallback(() => {
    setState(!state);
  }, [state]);

  return [state, toggleState];
};

export default useToggleState;
